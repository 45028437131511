import { Injectable } from '@angular/core';
import { DevicesService } from './devices.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private deviceService: DevicesService
  ) { }

  downloadFile(urlToReach: string): void {
    if (this.deviceService.isDesktop()) {
      var lastUrlItem = urlToReach.split("/").pop()

      fetch(urlToReach)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = lastUrlItem || "download"
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => console.log('Downloading file failed.'))
    } else {
      window.open(urlToReach, "_blank")
    }
  }
}
