<div id="product-modal-actions">
  <button
    type="button"
    id="button-360"
    class="product-modal-actions-button ga-clic-nsm-2021-360-button"
    [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
    (click)="button360Clicked()"
    *ngIf="showButton360"
  ></button>
  <button
    type="button"
    id="button-video"
    class="product-modal-actions-button ga-clic-nsm-2021-video-button"
    [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
    (click)="buttonVideoClicked()"
    *ngIf="showButtonVideo"
  ></button>
  <button
    type="button"
    id="button-link"
    class="product-modal-actions-button ga-clic-nsm-2021-link-button"
    [attr.data-titlega]="product?.eqi + ' - ' + product?.name"
    (click)="buttonLinkClicked()"
    *ngIf="showButtonLink"
  ></button>
</div>
