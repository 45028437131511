import { Injectable } from '@angular/core';

import { MenuCategory, MenuCategoriesService } from './menu-categories.service'

import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  constructor(private menuCategoriesService: MenuCategoriesService) { }

  getMenuCategories(): Observable<MenuCategory[]> {
    return this.menuCategoriesService.watch()
      .valueChanges
      .pipe(
        first(),
        // Use of JSON parse/stringify to be able to mutate object for sorting/replace
        // as Apollo returs immutable objects
        map(result => JSON.parse(JSON.stringify(result.data.categories)))
      );
  }
}
