import { inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Apollo, gql } from "apollo-angular";
import { UploadService } from "./upload.service";
import {
  AddColorInput,
  Color as ColorGQL,
  UpdateColorInput as UpdateColorInputGQL,
} from "../types/graphql/graphql.type";
import { Color } from "../types/color.type";
import { environment } from "src/environments/environment";

export type CreateColorInput = AddColorInput;
export type UpdateColorInput = UpdateColorInputGQL;

type ColorsResponse = {
  colors: ColorGQL[];
};

type CreateColorResponse = {
  createColor: ColorGQL;
};

type CreateColorVariables = {
  color: AddColorInput;
};

type UpdateColorResponse = {
  updateColor: ColorGQL;
};

type UpdateColorVariables = {
  color: UpdateColorInputGQL;
};

type DeleteColorResponse = {
  deleteColor: boolean;
};

type DeleteColorVariables = {
  colorId: number;
};

@Injectable({
  providedIn: "root",
})
export class ColorsService {
  readonly IMAGE_UPLOAD_FOLDER: string = "colors";

  private _apollo = inject(Apollo);
  private _uploadService = inject(UploadService);

  private _staticFiles: string[] = [
    "beige-oyster.png",
    "black-chrome-gun-metal.png",
    "black.png",
    "blue.png",
    "brown-black-rust-safeway.png",
    "clear.png",
    "cocoa-maple-wood.png",
    "coppervein.png",
    "dark-stain.png",
    "delhaize-lozier-silver.png",
    "green.png",
    "grey.png",
    "ivory.png",
    "light-stain.png",
    "list.txt",
    "orange.png",
    "pewter.png",
    "purple.png",
    "qt-silver-tiger-drylac.png",
    "red.png",
    "silver-silverein.png",
    "white.png",
    "wood.png",
    "yellow.png",
    "zinc.png",
  ];

  private _GET_COLORS_QUERY = gql`
    query GetColors {
      colors {
        code
        id
        image
        name
      }
    }
  `;

  getColors(): Observable<Color[]> {
    return this._apollo
      .watchQuery<ColorsResponse>({
        query: this._GET_COLORS_QUERY,
      })
      .valueChanges.pipe(
        map(({ data: { colors } }) =>
          colors.map((color) => this.colorGQLtoColor(color)),
        ),
      );
  }

  private colorGQLtoColor(color: ColorGQL): Color {
    return {
      id: Number(color.id), // Temporary until ID type is fixed in backend
      name: color.name,
      image: this.parseImage(color.image),
      code: color.code,
    };
  }

  private parseImage(name: string): string | undefined {
    if (name === "") return undefined;

    if (this._staticFiles.includes(name))
      return `assets/images/products/colors/${name}`;

    return `${environment.container}/assets/${this.IMAGE_UPLOAD_FOLDER}/${name}`;
  }

  uploadImage(file: File): Observable<string> {
    return this._uploadService.uploadAsset(file, this.IMAGE_UPLOAD_FOLDER)
      .upload$;
  }

  createColor(colorData: CreateColorInput): Observable<void> {
    return this._apollo
      .mutate<CreateColorResponse, CreateColorVariables>({
        mutation: gql`
          mutation CreateColor($color: AddColorInput!) {
            createColor(color: $color) {
              id
              code
              image
              name
            }
          }
        `,
        variables: { color: colorData },
        update: (cache, { data }) => {
          const existingData = cache.readQuery<ColorsResponse>({
            query: this._GET_COLORS_QUERY,
          });
          if (existingData && data?.createColor) {
            cache.writeQuery({
              query: this._GET_COLORS_QUERY,
              data: { colors: [...existingData.colors, data?.createColor] },
            });
          }
        },
      })
      .pipe(map(({ data }) => {}));
  }

  updateColor(colorData: UpdateColorInput): Observable<void> {
    return this._apollo
      .mutate<UpdateColorResponse, UpdateColorVariables>({
        mutation: gql`
          mutation UpdateColor($color: UpdateColorInput!) {
            updateColor(color: $color) {
              id
              code
              image
              name
            }
          }
        `,
        variables: { color: colorData },
      })
      .pipe(map(({ data }) => {}));
  }

  deleteColor(id: number): Observable<void> {
    return this._apollo
      .mutate<DeleteColorResponse, DeleteColorVariables>({
        mutation: gql`
          mutation DeleteColor($colorId: Int!) {
            deleteColor(colorId: $colorId)
          }
        `,
        variables: { colorId: id },
        update: (cache, { data }) => {
          if (data?.deleteColor) {
            const identity = cache.identify({ id, __typename: "Color" });
            cache.evict({ id: identity, broadcast: true });
            cache.gc();
          }
        },
      })
      .pipe(
        map(({ data }) => {
          if (!data?.deleteColor) {
            throw "Error deleting Color";
          }
        }),
      );
  }
}
