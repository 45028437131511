<div class="row catalog pb-4" *ngIf="homepageSettings"
    [ngClass]="{'pt-0': sharedService.environmentIsFSV(), 'pt-4': !sharedService.environmentIsFSV()}">
    <div class="col-12 col-lg-6 left" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
        <div class="btn-wrap">
            <img [src]="sharedService.getEnvironmentContainer() + homepageSettings['asset-homepage-top-left'] +
                '?timestamp=' + timestamp">
            <div class="catalog-button" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
                <button class="btn" (click)="navigateToUrl(homepageSettings['action-homepage-top-left'], $event)">
                    <img [src]="sharedService.getEnvironmentContainer() + homepageSettings['asset-homepage-button-top-left'] + '?timestamp=' + timestamp"
                        class="ga-clic-homepage" data-titlega="Browse The Catalog">
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 right" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
        <div class="row top" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
            <div class="col-12 h-100 top">
                <div class="btn-wrap">
                    <img [src]="sharedService.getEnvironmentContainer() + homepageSettings['asset-homepage-top-right'] +
                        '?timestamp=' + timestamp">
                    <div class="red-hot-button" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
                        <button class="btn"
                            (click)="navigateToUrl(homepageSettings['action-homepage-top-right'], $event)">
                            <img [src]="sharedService.getEnvironmentContainer() + homepageSettings['asset-homepage-button-top-right'] + '?timestamp=' + timestamp"
                                class="ga-clic-homepage" data-titlega="View All Special Offers">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row middle" *ngIf="!sharedService.environmentIsFSV()">
            <div class="col-12" h-100>
            </div>
        </div>
        <div class="row bottom" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
            <div class="col-12 h-100">
                <button class="btn btn-content"
                    (click)="clientFunctions[sharedService.getEnvironmentId()]?.homepageBottomRightClicked()">
                    <img [src]="sharedService.getEnvironmentContainer() + homepageSettings['asset-homepage-bottom-right'] + '?timestamp=' + timestamp"
                        class="bottom ga-clic-homepage" data-titlega="New A.C.E Designs">
                    <div class="ace-button" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
                        <button class="btn"
                            (click)="navigateToUrl(homepageSettings['action-homepage-button-bottom-right'], $event)">
                            <img [src]="sharedService.getEnvironmentContainer() + homepageSettings['asset-homepage-button-bottom-right'] + '?timestamp=' + timestamp"
                                class="ga-clic-homepage" data-titlega="Check Out The Latest Innovation Here">
                        </button>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row pt-3 pb-3 homepage-sections" *ngFor="let homepageSection of homepageSections">
    <div class="col-12 title-text">
        <div class="title">
            {{ homepageSection.name }}
        </div>
    </div>
    <div class="col-12">
        <div class="separator mb-4"></div>
    </div>
    <div class="row ml-0 mr-0 content w-100 flex-center-center" *ngIf="!isMobile"
        [ngClass]="{ 'custom-background': homepageSection.showBackground === true}">
        <div class="col-17 col-img mt-3 mb-3" *ngFor="let homepageSectionItem of homepageSection.items">
            <button class="btn" (click)="navigateToUrl(homepageSectionItem.targetUrl ?? '', $event)">
                <div class="image-with-badge">
                    <img class="ga-clic-homepage"
                        [attr.data-titlega]="homepageSection.name + ' - ' + homepageSectionItem.name" [src]="sharedService.getEnvironmentContainer() + '/assets/homepageCategories/' +
                        homepageSectionItem.imageUrl + '?timestamp=' + timestamp">
                    <img *ngIf="homepageSettings && homepageSectionItem.badgeKey !== null && homepageSectionItem.badgeKey !== 'null'"
                        [src]="sharedService.getEnvironmentContainer()
                        + '/assets/homepageBadges/' +
                        homepageSettings[homepageSectionItem.badgeKey!] + '?timestamp=' + timestamp" class="image-badge"
                        [ngClass]="{ 'image-with-shadow': homepageSection.name === 'Popular Categories'}">
                </div>
            </button>
        </div>
    </div>
    <div class="row ml-0 mr-0 w-100" *ngIf="isMobile">
        <div class="col-12 col-link" *ngFor="let homepageSectionItem of homepageSection.items">
            <div>
                <button class="btn ga-clic-homepage" (click)="navigateToUrl(homepageSectionItem.targetUrl ?? '', $event)"
                    [attr.data-titlega]="homepageSection.name + ' - ' + homepageSectionItem.name">
                    <span>{{ homepageSectionItem.name }}</span>
                    <i class="material-icons">chevron_right</i>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ie-modal" tabindex="-1" role="dialog" aria-labelledby="ieModal" aria-hidden="true"
    data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ieModal">Internet Explorer detected</h5>
            </div>
            <div class="modal-body">
                <p class="description mb-0">
                    Please note that this site is optimized for the Google Chrome web browser.
                    <br>
                    Users of older browsers such as Internet Explorer 11 may notice performance issues.
                    <br><br>
                    For an optimal browsing experience, please load this site on Google Chrome.
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" id="help-close" class="btn btn-1" (click)="closeIeModal()">Close</button>
            </div>
        </div>
    </div>
</div>