import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CategoryService, Category } from './category.service'
import { BugService, Bug } from './bug.service'
import { ParentCategoryService } from './parent-category.service'

import { environment } from 'src/environments/environment'

import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public canAccessBitBetsUrl = false

  enableAccessBitBetsUrl(): void { this.canAccessBitBetsUrl = true }
  disableAccessBitBetsUrl(): void { this.canAccessBitBetsUrl = false }

  constructor(
    private router: Router,
    private categoryService: CategoryService,
    private parentCategoryService: ParentCategoryService,
    private bugService: BugService) { }

  getCategoryId(categoryName: string): Observable<Category> {
    return this.categoryService.watch({ categoryName: categoryName })
      .valueChanges
      .pipe(
        first(),
        // Use of JSON parse/stringify to be able to mutate object for sorting/replace
        // as Apollo returs immutable objects
        map(result => JSON.parse(JSON.stringify(result.data.categoryByName)))
      );
  }

  getParentCategory(categoryId: number): Observable<Category> {
    return this.parentCategoryService.watch({ categoryId: categoryId })
      .valueChanges
      .pipe(
        first(),
        // Use of JSON parse/stringify to be able to mutate object for sorting/replace
        // as Apollo returs immutable objects
        map(result => JSON.parse(JSON.stringify(result.data.getParentCategory)))
      );
  }

  navigateToCategoryName(categoryName: string) {
    this.getCategoryId(categoryName)
      .subscribe(category => {
        if (category.id) {
          this.router.navigateByUrl('products/categories/' + category.id)
          window.scrollTo(0, 0)
        }
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  navigateToCategoryId(categoryId: number) {
    if (categoryId === -1) {
      this.router.navigateByUrl('products/categories/all')
    } else {
      this.router.navigateByUrl('products/categories/' + categoryId)
    }

    window.scrollTo(0, 0)
  }

  getBugId(bugName: string): Observable<Category> {
    return this.bugService.watch({ bugName: bugName })
      .valueChanges
      .pipe(
        first(),
        // Use of JSON parse/stringify to be able to mutate object for sorting/replace
        // as Apollo returs immutable objects
        map(result => JSON.parse(JSON.stringify(result.data.bugByName)))
      );
  }

  navigateToBugName(bugName: string) {
    this.getBugId(bugName)
      .subscribe(bug => {
        if (bug.id) {
          this.router.navigateByUrl('products/bugs/' + bug.id)
          window.scrollTo(0, 0)
        }
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  navigateToBugId(bugId: number) {
    if (bugId) {
      this.router.navigateByUrl('products/bugs/' + bugId)
      window.scrollTo(0, 0)
    }
  }

  navigateToProductId(productId: number) {
    if (productId) {
      this.router.navigateByUrl('products/product/' + productId)
      window.scrollTo(0, 0)
    }
  }

  navigateToSearchByKeyword(keyword: string) {
    this.router.navigateByUrl('products/search/' + encodeURIComponent(keyword))
  }

  navigateToColorId(colorId: number) {
    if (colorId) {
      this.router.navigateByUrl('products/colors/' + colorId)
      window.scrollTo(0, 0)
    }
  }

  navigateToPdf(pdfName: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl('pdf/' + pdfName)
      window.scrollTo(0, 0)
    })
  }

  navigateToUrl(url: string) {
    this.router.navigateByUrl(url)
  }

  browserIsIE() {
    return navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1
  }

  getEnvironmentId() {
    return environment.environmentId
  }

  getEnvironmentContainer() {
    return environment.container
  }

  environmentIsMCOE() {
    return this.getEnvironmentId() === 'mcoe'
  }

  environmentIsFSV() {
    return this.getEnvironmentId() === 'fsv'
  }

  environmentIsPBNA() {
    return this.getEnvironmentId() === 'pbna'
  }
}
