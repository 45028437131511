import { Component, input, computed, inject } from "@angular/core";
import { Product } from "src/app/types/product.type";
import { BugsService } from "src/app/services/bugs.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { ColorsService } from "src/app/services/colors.service";
import { Bug } from "src/app/types/bug.type";
import { Color } from "src/app/types/color.type";

@Component({
  selector: "fc-sellsheet-template",
  templateUrl: "./sellsheet-template.component.html",
  styleUrls: ["./sellsheet-template.component.scss"],
  standalone: true,
  imports: [],
})
export class SellsheetTemplateComponent {
  product = input.required<Product>();

  private _bugsService = inject(BugsService);
  private _colorsService = inject(ColorsService);

  image = computed(() => {
    const product = this.product();
    return product.images.length > 0
      ? product.images[0].largeThumb
      : "assets/images/common/no_image.svg";
  });

  eqiText = computed(() => {
    const product = this.product();
    return product.colors.length > 0 && product.colors[0].fullEqi !== undefined
      ? product.eqi
      : undefined;
  });

  dimensions = computed(() => {
    const w = this.product().width;
    const h = this.product().height;
    const d = this.product().depth;
    return w !== undefined || h !== undefined || d
      ? `${w} x ${d} x ${h}`
      : undefined;
  });

  colors = toSignal(this._colorsService.getColors(), { initialValue: [] });
  productColors = computed(() => {
    return this.product()
      .colors.map((pc) => this.colors().find((c) => c.id === pc.id))
      .filter((c): c is Color => !!c);
  });

  bugs = toSignal(this._bugsService.getBugs(), { initialValue: [] });
  productBugs = computed(() => {
    return this.product()
      .bugs.map((pb) => this.bugs().find((c) => c.id === pb))
      .filter((pb): pb is Bug => !!pb);
  });
}
