import { z } from "zod";

export type Product = {
  readonly id: number;
  readonly name: string;
  readonly sortId: number;
  readonly eqi: string;
  readonly uom?: string;
  readonly depth?: string;
  readonly width?: string;
  readonly height?: string;
  readonly size?: string;
  readonly metadata: ReadonlyArray<string>;
  readonly images: ReadonlyArray<ProductImage>;
  readonly notes?: string;
  readonly properties?: ProductProperties;
  readonly hasSellSheet: boolean;
  readonly sellsheet?: ProductSellsheet;
  readonly colors: ReadonlyArray<ProductColor>;
  readonly bugs: ReadonlyArray<number>;
  readonly categories: ReadonlyArray<ProductCategory>;
};

export type ProductEditSearch = Pick<Product, "id" | "name" | "eqi"> & {
  readonly thumbnail?: string;
  readonly categories: ReadonlyArray<ProductEditSearchCategory>;
  readonly bugs: ReadonlyArray<string>;
  readonly colors: ReadonlyArray<string>;
};

export type ProductImage = {
  readonly image: string;
  readonly smallThumb: string;
  readonly largeThumb: string;
};

export type ProductProperties = {
  readonly hasSellSheet?: boolean;
  readonly dimensions?: string;
  readonly unitsPerCarton?: string;
  readonly supplier?: string;
  readonly accountProgram?: string;
  readonly imagesCount?: number;
  readonly images?: ReadonlyArray<string>;
  readonly hasOnePager?: boolean;
  readonly brand?: string;
  readonly estPricing?: string;
  readonly supplierOnePager?: string;
  readonly imageOnePager?: string;
  readonly mustWin?: string;
  readonly greatLocation?: string;
  readonly ePop?: string;
  readonly enableShareableLink?: boolean;
  readonly has360View?: boolean;
  readonly hasVideo?: boolean;
  readonly price?: string;
};

export const PRODUCT_PROPERTIES_SCHEMA = z.object({
  hasSellSheet: z.boolean().optional(),
  dimensions: z.string().optional(),
  unitsPerCarton: z.string().optional(),
  supplier: z.string().optional(),
  accountProgram: z.string().optional(),
  imagesCount: z.number().optional(),
  images: z.array(z.string()).optional(),
  hasOnePager: z.boolean().optional(),
  brand: z.string().optional(),
  estPricing: z.string().optional(),
  supplierOnePager: z.string().optional(),
  imageOnePager: z.string().optional(),
  mustWin: z.string().optional(),
  greatLocation: z.string().optional(),
  ePop: z.string().optional(),
  enableShareableLink: z.boolean().optional(),
  has360View: z.boolean().optional(),
  hasVideo: z.boolean().optional(),
  price: z.string().optional(),
});

export type ProductSellsheetDisplaySpec = {
  readonly rationale?: string;
  readonly graphicExecution?: string;
  readonly suggestedLocations?: string;
  readonly specifications?: string;
  readonly packout?: string;

  readonly estCost?: string;
  readonly estFreight?: string;
};

export const PRODUCT_SELLSHEET_DISPLAY_SPEC_SCHEMA = z.object({
  rationale: z.string().optional(),
  graphicExecution: z.string().optional(),
  suggestedLocations: z.string().optional(),
  specifications: z.string().optional(),
  packout: z.string().optional(),

  estCost: z.string().optional(),
  estFreight: z.string().optional(),
});

export type ProductSellsheetEconomics = {
  readonly price?: string;
  readonly fundingLevel?: string;
  readonly pmeBreakeven?: string;
  readonly turnsPerWeek?: string;
  readonly estimatedTurnForBreakeven?: string;
  readonly unitsToBreakeven?: string;
  readonly estimatedAnnualProfitability?: string;

  readonly SDVHoldingPower?: string;
  readonly retailSales?: string;
  readonly annualTurns?: string;
  readonly annualRetailSales?: string;
  readonly annualRetailProfit?: string;
  readonly holdingPower1?: string;
  readonly holdingPower2?: string;
  readonly holdingPower3?: string;
  readonly holdingPower4?: string;
  readonly holdingPower5?: string;
  readonly holdingPower6?: string;
  readonly holdingPower7?: string;
  readonly holdingPower8?: string;
  readonly holdingPower9?: string;
};

export const PRODUCT_SELLSHEET_ECONOMICS = z.object({
  price: z.string().optional(),
  fundingLevel: z.string().optional(),
  pmeBreakeven: z.string().optional(),
  turnsPerWeek: z.string().optional(),
  estimatedTurnForBreakeven: z.string().optional(),
  unitsToBreakeven: z.string().optional(),
  estimatedAnnualProfitability: z.string().optional(),

  SDVHoldingPower: z.string().optional(),
  retailSales: z.string().optional(),
  annualTurns: z.string().optional(),
  annualRetailSales: z.string().optional(),
  annualRetailProfit: z.string().optional(),
  holdingPower1: z.string().optional(),
  holdingPower2: z.string().optional(),
  holdingPower3: z.string().optional(),
  holdingPower4: z.string().optional(),
  holdingPower5: z.string().optional(),
  holdingPower6: z.string().optional(),
  holdingPower7: z.string().optional(),
  holdingPower8: z.string().optional(),
  holdingPower9: z.string().optional(),
});

export type ProductSellsheet = {
  readonly displaySpec: ProductSellsheetDisplaySpec;
  readonly economics: ProductSellsheetEconomics;
};

export const PRODUCT_SELLSHEET = z.object({
  displaySpec: PRODUCT_SELLSHEET_DISPLAY_SPEC_SCHEMA,
  economics: PRODUCT_SELLSHEET_ECONOMICS,
});

export type ProductColor = {
  readonly id: number;
  readonly fullEqi?: string;
};

export type ProductCategory = {
  readonly l1: string;
  readonly l2?: string;
  readonly l3?: string;
};

export type ProductEditSearchCategory = {
  readonly l1: { id: string; name: string };
  readonly l2?: { id: string; name: string };
  readonly l3?: { id: string; name: string };
};
