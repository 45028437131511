import { inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Apollo, gql } from "apollo-angular";
import {
  AddCategoryInput,
  Category as CategoryGQL,
  UpdateCategoryInput as UpdateCategoryInputGQL,
} from "../types/graphql/graphql.type";
import { Category, CategoryWithProducts } from "../types/category.type";
import { productGQLtoProduct } from "../tools/cms.tools";

export type CreateCategoryInput = AddCategoryInput;
export type UpdateCategoryInput = Omit<UpdateCategoryInputGQL, "id"> & {
  id: string;
};

type CategoriesResponse = {
  categories: CategoryGQL[];
};

type CategoriesWithProductsResponse = {
  productsByCategories: CategoryGQL[];
};

type CategoriesWithProductsVariables = {
  categoryIds: number[];
};

type CategoryWithProductsResponse = {
  productsByCategory: CategoryGQL;
};

type CategoryWithProductsVariables = {
  categoryId: number;
};

type CreateCategoryResponse = {
  createCategory: CategoryGQL;
};

type CreateCategoryVariables = {
  category: AddCategoryInput;
};

type UpdateCategoryResponse = {
  updateCategory: CategoryGQL;
};

type UpdateCategoryVariables = {
  category: UpdateCategoryInputGQL;
};

type DeleteCategoryResponse = {
  deleteCategory: {
    deletedCategories: number[];
    deletedProducts: number[];
  };
};

type DeleteCategoryVariables = {
  category: UpdateCategoryInputGQL;
};

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  private _apollo = inject(Apollo);

  private _GET_CATEGORIES_QUERY = gql`
    query GetCategories {
      categories {
        id
        name
        displayOrder
        children {
          id
          name
          displayOrder
          children {
            id
            name
            displayOrder
          }
        }
      }
    }
  `;

  getCategories(): Observable<Category[]> {
    return this._apollo
      .watchQuery<CategoriesResponse>({
        query: this._GET_CATEGORIES_QUERY,
      })
      .valueChanges.pipe(
        map(({ data: { categories } }) =>
          categories.map((category) => this.parseCategoryGQL(category)),
        ),
      );
  }

  private parseCategoryGQL(category: CategoryGQL): Category {
    return {
      id: category.id,
      name: category.name,
      displayOrder: category.displayOrder,
      children:
        category.children && category.children?.length > 0
          ? category.children.map((c) => this.parseCategoryGQL(c))
          : [],
    };
  }

  getCategoriesWithProducts(
    ids?: number[],
  ): Observable<CategoryWithProducts[]> {
    if (!ids) {
      return this._apollo
        .watchQuery<CategoriesResponse>({
          query: gql`
            query CategoriesWithProducts {
              categories {
                id
                name
                displayOrder
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
                products {
                  id
                  name
                  sortId
                  eqi
                  uom
                  depth
                  width
                  height
                  size
                  metadata
                  image
                  notes
                  properties
                  hasSellSheet
                  sellSheet {
                    displaySpec
                    economics
                  }
                  productColors {
                    fullEqi
                    color {
                      id
                    }
                  }
                  bugs {
                    id
                  }
                  categories {
                    id
                    parent {
                      id
                      parent {
                        id
                      }
                    }
                  }
                }
                children {
                  id
                  name
                  displayOrder
                  parent {
                    id
                    name
                    parent {
                      id
                      name
                    }
                  }
                  products {
                    id
                    name
                    sortId
                    eqi
                    uom
                    depth
                    width
                    height
                    size
                    metadata
                    image
                    notes
                    properties
                    hasSellSheet
                    sellSheet {
                      displaySpec
                      economics
                    }
                    productColors {
                      fullEqi
                      color {
                        id
                      }
                    }
                    bugs {
                      id
                    }
                    categories {
                      id
                      parent {
                        id
                        parent {
                          id
                        }
                      }
                    }
                  }
                  children {
                    id
                    name
                    displayOrder
                    parent {
                      id
                      name
                      parent {
                        id
                        name
                      }
                    }
                    products {
                      id
                      name
                      sortId
                      eqi
                      uom
                      depth
                      width
                      height
                      size
                      metadata
                      image
                      notes
                      properties
                      hasSellSheet
                      sellSheet {
                        displaySpec
                        economics
                      }
                      productColors {
                        fullEqi
                        color {
                          id
                        }
                      }
                      bugs {
                        id
                      }
                      categories {
                        id
                        parent {
                          id
                          parent {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
        })
        .valueChanges.pipe(
          map(({ data: { categories } }) =>
            categories.map((category) =>
              this.parseCategoryWithProductsGQL(category),
            ),
          ),
        );
    }

    return this._apollo
      .watchQuery<
        CategoriesWithProductsResponse,
        CategoriesWithProductsVariables
      >({
        query: gql`
          query Categories($categoryIds: [Int!]!) {
            productsByCategories(categoryIds: $categoryIds) {
              id
              name
              displayOrder
              parent {
                id
                name
                parent {
                  id
                  name
                }
              }
              products {
                id
                name
                sortId
                eqi
                uom
                depth
                width
                height
                size
                metadata
                image
                notes
                properties
                hasSellSheet
                sellSheet {
                  displaySpec
                  economics
                }
                productColors {
                  fullEqi
                  color {
                    id
                  }
                }
                bugs {
                  id
                }
                categories {
                  id
                  parent {
                    id
                    parent {
                      id
                    }
                  }
                }
              }
              children {
                id
                name
                displayOrder
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
                products {
                  id
                  name
                  sortId
                  eqi
                  uom
                  depth
                  width
                  height
                  size
                  metadata
                  image
                  notes
                  properties
                  hasSellSheet
                  sellSheet {
                    displaySpec
                    economics
                  }
                  productColors {
                    fullEqi
                    color {
                      id
                    }
                  }
                  bugs {
                    id
                  }
                  categories {
                    id
                    parent {
                      id
                      parent {
                        id
                      }
                    }
                  }
                }
                children {
                  id
                  name
                  displayOrder
                  parent {
                    id
                    name
                    parent {
                      id
                      name
                    }
                  }
                  products {
                    id
                    name
                    sortId
                    eqi
                    uom
                    depth
                    width
                    height
                    size
                    metadata
                    image
                    notes
                    properties
                    hasSellSheet
                    sellSheet {
                      displaySpec
                      economics
                    }
                    productColors {
                      fullEqi
                      color {
                        id
                      }
                    }
                    bugs {
                      id
                    }
                    categories {
                      id
                      parent {
                        id
                        parent {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { categoryIds: ids },
      })
      .valueChanges.pipe(
        map(({ data: { productsByCategories } }) =>
          productsByCategories.map((category) =>
            this.parseCategoryWithProductsGQL(category),
          ),
        ),
      );
  }

  getCategoryWithProducts(id: number): Observable<CategoryWithProducts> {
    return this._apollo
      .watchQuery<CategoryWithProductsResponse, CategoryWithProductsVariables>({
        query: gql`
          query CategoryWithProducts($categoryId: Int!) {
            productsByCategory(categoryId: $categoryId) {
              id
              name
              displayOrder
              parent {
                id
                name
                parent {
                  id
                  name
                }
              }
              products {
                id
                name
                sortId
                eqi
                uom
                depth
                width
                height
                size
                metadata
                image
                notes
                properties
                hasSellSheet
                sellSheet {
                  displaySpec
                  economics
                }
                productColors {
                  fullEqi
                  color {
                    id
                  }
                }
                bugs {
                  id
                }
                categories {
                  id
                  parent {
                    id
                    parent {
                      id
                    }
                  }
                }
              }
              children {
                id
                name
                displayOrder
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
                products {
                  id
                  name
                  sortId
                  eqi
                  uom
                  depth
                  width
                  height
                  size
                  metadata
                  image
                  notes
                  properties
                  hasSellSheet
                  sellSheet {
                    displaySpec
                    economics
                  }
                  productColors {
                    fullEqi
                    color {
                      id
                    }
                  }
                  bugs {
                    id
                  }
                  categories {
                    id
                    parent {
                      id
                      parent {
                        id
                      }
                    }
                  }
                }
                children {
                  id
                  name
                  displayOrder
                  parent {
                    id
                    name
                    parent {
                      id
                      name
                    }
                  }
                  products {
                    id
                    name
                    sortId
                    eqi
                    uom
                    depth
                    width
                    height
                    size
                    metadata
                    image
                    notes
                    properties
                    hasSellSheet
                    sellSheet {
                      displaySpec
                      economics
                    }
                    productColors {
                      fullEqi
                      color {
                        id
                      }
                    }
                    bugs {
                      id
                    }
                    categories {
                      id
                      parent {
                        id
                        parent {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { categoryId: id },
      })
      .valueChanges.pipe(
        map(({ data: { productsByCategory } }) =>
          this.parseCategoryWithProductsGQL(productsByCategory),
        ),
      );
  }

  private parseCategoryWithProductsGQL(
    category: CategoryGQL,
  ): CategoryWithProducts {
    return {
      id: category.id,
      name: category.name,
      displayOrder: category.displayOrder,
      products:
        category.products && category.products.length > 0
          ? category.products
              .map((p) => productGQLtoProduct(p))
              .sort((a, b) => a.sortId - b.sortId)
          : [],
      children:
        category.children && category.children.length > 0
          ? category.children.map((c) => this.parseCategoryWithProductsGQL(c))
          : [],
      parent: category.parent
        ? {
            id: category.parent.id,
            name: category.parent.name,
            parent: category.parent.parent
              ? {
                  id: category.parent.parent.id,
                  name: category.parent.parent.name,
                }
              : undefined,
          }
        : undefined,
    };
  }

  createCategory(categoryData: CreateCategoryInput): Observable<void> {
    return this._apollo
      .mutate<CreateCategoryResponse, CreateCategoryVariables>({
        mutation: gql`
          mutation CreateCategory($category: AddCategoryInput!) {
            createCategory(category: $category) {
              id
              name
              displayOrder
              parent {
                id
              }
              children {
                id
                name
                displayOrder
                children {
                  id
                  name
                  displayOrder
                }
              }
            }
          }
        `,
        variables: { category: categoryData },
        update: (cache, { data }) => {
          if (data) {
            const existingData = cache.readQuery<CategoriesResponse>({
              query: this._GET_CATEGORIES_QUERY,
            });
            if (existingData) {
              const newCategory = data.createCategory;
              const newCategoryParentId = newCategory.parent?.id;
              if (newCategoryParentId) {
                cache.writeQuery({
                  query: this._GET_CATEGORIES_QUERY,
                  data: {
                    categories: existingData.categories.map((l1Category) => {
                      if (l1Category.id === newCategoryParentId) {
                        return {
                          ...l1Category,
                          children: [...l1Category.children, newCategory],
                        };
                      }

                      return {
                        ...l1Category,
                        children: l1Category.children.map((l2Category) => {
                          if (l2Category.id === newCategoryParentId) {
                            return {
                              ...l2Category,
                              children: [...l2Category.children, newCategory],
                            };
                          }
                          return l2Category;
                        }),
                      };
                    }),
                  },
                });
              } else {
                cache.writeQuery({
                  query: this._GET_CATEGORIES_QUERY,
                  data: {
                    categories: [...existingData.categories, newCategory],
                  },
                });
              }
            }
          }
        },
      })
      .pipe(map(({ data }) => {}));
  }

  updateCategory(categoryData: UpdateCategoryInput): Observable<void> {
    const idNum = Number(categoryData.id);
    if (Number.isNaN(idNum)) {
      return throwError(() => new Error(`Invalid bug ID: ${categoryData.id}`));
    }

    return this._apollo
      .mutate<UpdateCategoryResponse, UpdateCategoryVariables>({
        mutation: gql`
          mutation UpdateCategory($category: UpdateCategoryInput!) {
            updateCategory(category: $category) {
              id
              name
              displayOrder
            }
          }
        `,
        variables: {
          category:
            this.updateCategoryInputToUpdateCategoryInputGQL(categoryData),
        },
      })
      .pipe(map(({ data }) => {}));
  }

  private updateCategoryInputToUpdateCategoryInputGQL(
    data: UpdateCategoryInput,
  ): UpdateCategoryInputGQL {
    const idNum = Number(data.id);
    if (Number.isNaN(idNum)) {
      throw `Invalid category ID: ${data.id}`;
    }

    return {
      ...data,
      id: idNum,
    };
  }

  deleteCategory(id: string): Observable<void> {
    const idNum = Number(id);
    if (Number.isNaN(idNum)) {
      return throwError(() => new Error(`Invalid category ID: ${id}`));
    }

    return this._apollo
      .mutate<DeleteCategoryResponse, DeleteCategoryVariables>({
        mutation: gql`
          mutation DeleteCategory($category: UpdateCategoryInput!) {
            deleteCategory(category: $category) {
              deletedCategories
              deletedProducts
            }
          }
        `,
        variables: { category: { id: idNum } },
        update: (cache, { data }) => {
          if (data) {
            data.deleteCategory.deletedCategories.map((c) => {
              const identity = cache.identify({ id, __typename: "Category" });
              cache.evict({ id: identity, broadcast: true });
            });
            data.deleteCategory.deletedProducts.map((c) => {
              const identity = cache.identify({ id, __typename: "Product" });
              cache.evict({ id: identity, broadcast: true });
            });
            cache.gc();
          }
        },
      })
      .pipe(
        map(({ data }) => {
          // if (!data?.deleteBug) {
          //   throw "Error deleting Bug";
          // }
        }),
      );
  }
}
