import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { combineLatest, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { Product, ProductEditSearch } from "../types/product.type";
import { SearchAutoComplete } from "../types/search.type";
import { environment } from "src/environments/environment";
import { ProductsService } from "./products.service";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  private _httpClient = inject(HttpClient);
  private _productsService = inject(ProductsService);

  autoComplete(text: string): Observable<SearchAutoComplete[]> {
    return this._httpClient
      .get<
        SearchAutoComplete[]
      >(`${environment.endPoint}/search/completion?k=${text}`)
      .pipe(
        catchError((e) => {
          console.error("Autocomplete query error", e);
          return of([]);
        }),
      );
  }

  searchEditProduct(text: string): Observable<ProductEditSearch[]> {
    return this.autoComplete(text).pipe(
      switchMap((results) => {
        const obs$ = results.map((r) => {
          switch (r.type) {
            case "Bug":
              return this._productsService.getProductsEditSearchByBug(r.id);
            case "Category":
              return this._productsService.getProductsEditSearchByCategory(
                r.id,
              );
            case "Color":
              return this._productsService.getProductsEditSearchByColor(r.id);
            case "Product":
              return this._productsService
                .getProductEditSearch(r.id)
                .pipe(map((p) => [p]));
          }
        });

        obs$.push(this._productsService.getProductsEditSearchByKeyword(text));

        return combineLatest(obs$);
      }),
      map((productsProducts) => {
        // Flatten results
        const products = productsProducts.reduce<ProductEditSearch[]>(
          (allProducts, products) => {
            allProducts.push(...products);
            return allProducts;
          },
          [],
        );

        // Remove duplicates
        return [...new Map(products.map((p) => [p.id, p])).values()];
      }),
    );
  }

  searchProduct(text: string): Observable<Product[]> {
    return this.autoComplete(text).pipe(
      switchMap((results) => {
        const obs$ = results.map((r) => {
          switch (r.type) {
            case "Bug":
              return this._productsService.getProductsByBug(r.id);
            case "Category":
              return this._productsService.getProductsByCategory(r.id);
            case "Color":
              return this._productsService.getProductsByColor(r.id);
            case "Product":
              return of<Product[]>([]); // Use productsByKeyword graphQL query instead to get all products in one query
          }
        });

        obs$.push(this._productsService.getProductsByKeyword(text));

        return combineLatest(obs$);
      }),
      map((productsProducts) => {
        // Flatten results
        const products = productsProducts.reduce<Product[]>(
          (allProducts, products) => {
            allProducts.push(...products);
            return allProducts;
          },
          [],
        );

        // Remove duplicates
        return [...new Map(products.map((p) => [p.id, p])).values()];
      }),
    );
  }
}
