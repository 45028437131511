import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DownloadService } from "src/app/core/services/download.service";
import { NgIf } from "@angular/common";
import { Product } from "src/app/types/product.type";

declare var $: any;

@Component({
  selector: "fc-product-modal-actions",
  templateUrl: "./product-modal-actions.component.html",
  styleUrls: ["./product-modal-actions.component.scss"],
  standalone: true,
  imports: [NgIf],
})
export class ProductModalActionsComponent implements OnInit, AfterViewInit {
  @Input() product?: Product;
  @Output() button360ClickedEmitter = new EventEmitter<void>();

  private prefixVideoUrl =
    "https://fritolay.blob.core.windows.net/images-products/videos";
  private nsmDomainUrl = "https://mcoe360.com/product-viewer";

  showButton360 = false;
  showButtonVideo = false;
  showButtonLink = false;

  constructor(private downloadService: DownloadService) {}

  ngOnInit(): void {
    this.showButton360 = this.product?.properties?.has360View ?? false;
    this.showButtonVideo = this.product?.properties?.hasVideo ?? false;
    this.showButtonLink =
      this.product?.properties?.enableShareableLink ?? false;
  }

  ngAfterViewInit(): void {
    if (this.showButtonLink) {
      $("#button-link").popover({
        placement: "top",
        content: "Copied to clipboard!",
      });
    }
  }

  button360Clicked(): void {
    this.button360ClickedEmitter.emit();
  }

  buttonVideoClicked(): void {
    this.downloadService.downloadFile(
      `${this.prefixVideoUrl}/${this.product?.eqi}.mp4`,
    );
  }

  buttonLinkClicked(): void {
    navigator.clipboard.writeText(`${this.nsmDomainUrl}/${this.product?.eqi}`);

    setTimeout(() => {
      $("#button-link").popover("hide");
    }, 2500);
  }
}
