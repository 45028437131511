import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query, gql } from 'apollo-angular';

export interface ParentCategory {
  id: number;
  name: string;
  parent?: ParentCategory;
}

export interface Category {
  id: number;
  name: string;
  parent?: ParentCategory;
  products: Product[];
  children: Category[];
}

export interface ProductsByCategory {
  productsByCategories: Category[];
}

@Injectable({
  providedIn: 'root'
})
export class ProductsCategoriesService extends Query<ProductsByCategory> {
  override document = gql`
    query productsByCategories($categoryIds: [Int!]!) {
      productsByCategories(categoryIds: $categoryIds) {
        id, name, parent {
          id, name, parent{ 
            id, name
          }
        }, products {
          id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
            fullEqi, color {
              id, code, name, image
            }
          }, bugs {
            id, name, imageUrl
          }, properties
    		}, children {
      		id, name, products {
          	id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
              fullEqi, color {
                id, code, name, image
              }
          	}, bugs {
            	id, name, imageUrl
          	}, properties
    			} , children {
            id, name, products {
              id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
                fullEqi, color {
                  id, code, name, image
                }
              }, bugs {
                id, name, imageUrl
              }, properties
            }
          }
    		}
      }
    }`;
}
