import { Injectable } from '@angular/core';

import { Query, gql } from 'apollo-angular';

export interface Bug {
  id: number;
}

export interface BugByName {
  bugByName: Bug
}

@Injectable({
  providedIn: 'root'
})
export class BugService extends Query<BugByName> {
  override document = gql`
    query bugByName($bugName: String!) {
      bugByName(bugName: $bugName) {
        id
      }
    }`;
}
