import { HttpClient, HttpEventType } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import { environment } from "src/environments/environment";

export type UploadAsset = {
  upload$: Observable<string>;
  progress$: Observable<number>;
};

@Injectable({
  providedIn: "root",
})
export class UploadService {
  readonly PRODUCT_IMAGE_UPLOAD_BASE_PATH: string = "upload/image/product";
  readonly ASSET_UPLOAD_BASE_PATH: string = "upload/asset";

  private _httpClient = inject(HttpClient);

  uploadProductImage(
    file: File,
    useOriginalName: boolean = false,
  ): UploadAsset {
    const fileToUpload = useOriginalName ? file : this.updateFilename(file);
    const formData = new FormData();
    formData.append("file", fileToUpload);

    const progress$ = new BehaviorSubject<number>(0);
    const upload$ = this.upload(
      `${environment.endPoint}/${this.PRODUCT_IMAGE_UPLOAD_BASE_PATH}`,
      formData,
      fileToUpload.name,
      progress$,
    );

    return { upload$, progress$ };
  }

  uploadAsset(
    file: File,
    folder: string,
    useOriginalName: boolean = false,
  ): UploadAsset {
    const fileToUpload = useOriginalName ? file : this.updateFilename(file);
    const formData = new FormData();
    formData.append("file", fileToUpload);

    const progress$ = new BehaviorSubject<number>(0);
    const upload$ = this.upload(
      `${environment.endPoint}/${this.ASSET_UPLOAD_BASE_PATH}?folder=${folder}`,
      formData,
      fileToUpload.name,
      progress$,
    );

    return { upload$, progress$ };
  }

  private updateFilename(file: File): File {
    const ext = file.name.split(".").pop();
    const filename = `${uuidv4()}.${ext}`;
    return new File([file], filename, { type: file.type });
  }

  private upload(
    path: string,
    formData: FormData,
    filename: string,
    progress$: BehaviorSubject<number>,
  ) {
    return this._httpClient
      .post(path, formData, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((events) => {
          if (events.type === HttpEventType.UploadProgress) {
            progress$.next(
              events.total && events.total > 0
                ? events.loaded / events.total
                : 0,
            );
          }

          if (events.type === HttpEventType.Response) {
            if (events.status !== 200) {
              throw `Error while uploading file - status: ${events.status} - msg: ${events.body}`;
            }

            return true;
          }

          progress$.next(1);

          return false;
        }),
        filter((v) => v),
        map(() => filename),
      );
  }
}
