import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query, gql } from 'apollo-angular';

export interface ProductsByColor {
  productsByColor: Product[];
}

@Injectable({
  providedIn: 'root'
})
export class ProductsByColorService extends Query<ProductsByColor> {
  override document = gql`
    query productsByColor($colorId: Int!) {
      productsByColor(colorId: $colorId) {
        id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
          fullEqi, color {
            id, code, name, image
          }
        }, bugs {
          id, name, imageUrl
        }, properties
      }
    }`;
}
