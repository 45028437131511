import { Injectable } from '@angular/core';

import { SettingsService } from './settings.service'
import { SettingsCUDService } from './settings-cud.service'
import { SettingService } from "./setting.service";
import { Setting } from '../interfaces/setting'
import { HomepageSectionCUDService } from './homepage-section-cud.service'
import { HomepageSectionItemCUDService } from './homepage-section-item-cud.service'
import { HomepageSectionService } from './homepage-section.service'
import { HomepageSection } from '../interfaces/homepage-section'
import { HomepageSectionItem } from '../interfaces/homepage-section-item'
import { HomepageCategoryCUDService } from './homepage-category-cud.service.'
import { HomepageCategory } from '../interfaces/homepage-category';

import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private settingsService: SettingsService,
    private settingsCUDService: SettingsCUDService,
    private settingService: SettingService,
    private homepageSectionCUDService: HomepageSectionCUDService,
    private homepageSectionItemCUDService: HomepageSectionItemCUDService,
    private homepageSectionService: HomepageSectionService,
    private homepageCategoryCUDService: HomepageCategoryCUDService
  ) { }

  getSettings(): Observable<Setting[]> {
    return this.settingsService.watch()
      .valueChanges
      .pipe(
        first(),
        // Use of JSON parse/stringify to be able to mutate object for sorting/replace
        // as Apollo returs immutable objects
        map(result => JSON.parse(JSON.stringify(result.data.settings)))
      );
  }

  getSettingByKey(key: string): Observable<Setting> {
    return this.settingService.watch({ key: key })
      .valueChanges
      .pipe(
        first(),
        // Use of JSON parse/stringify to be able to mutate object for sorting/replace
        // as Apollo returs immutable objects
        map(result => JSON.parse(JSON.stringify((result.data as any)['settingByKey'])))
      );
  }

  createSetting(key: string, value: string, displayName: string) {
    const setting = {
      key: key,
      value: value,
      displayName: displayName
    };

    return this.settingsCUDService.createSetting(setting)
  }

  editSetting(id: number, value: string, displayName: string) {
    const setting = {
      id: id,
      value: value,
      displayName: displayName
    };

    return this.settingsCUDService.editSetting(setting)
  }

  deleteSetting(settingId: number) {
    return this.settingsCUDService.deleteSetting(settingId)
  }

  getHomepageSections(): Observable<HomepageSection[]> {
    return this.homepageSectionService.watch()
      .valueChanges
      .pipe(
        first(),
        // Use of JSON parse/stringify to be able to mutate object for sorting/replace
        // as Apollo returs immutable objects
        map(result => JSON.parse(JSON.stringify(result.data.frontpageSections)))
      );
  }

  createSection(sectionName: string, displayOrder: number, showBackground: boolean) {
    const homepageSection = {
      name: sectionName,
      displayOrder: displayOrder,
      showBackground: showBackground
    };

    return this.homepageSectionCUDService.addSection(homepageSection)
  }

  deleteSection(sectionId: number) {
    const homepageSection: HomepageSection = {
      id: sectionId
    };

    return this.homepageSectionCUDService.removeSection(homepageSection)
  }

  editSection(sectionId: number, sectionName: string, displayOrder: number, showBackground: boolean) {
    const homepageSection = {
      id: sectionId,
      name: sectionName,
      displayOrder: displayOrder,
      showBackground: showBackground
    };

    return this.homepageSectionCUDService.editSection(homepageSection)
  }

  createSectionItem(itemFileName: string, itemName: string, itemTarget: string, itemDisplayOrder: number, linkSectionId: number, badgeKey: string) {
    const homepageSection: HomepageSection = {
      id: linkSectionId
    };

    const homepageSectionItem: HomepageSectionItem = {
      imageUrl: '' + itemFileName,
      name: itemName,
      targetUrl: itemTarget,
      displayOrder: itemDisplayOrder,
      linkedSection: homepageSection,
      badgeKey: badgeKey
    };

    return this.homepageSectionItemCUDService.addSectionItem(homepageSectionItem)
  }

  deleteSectionItem(sectionItemId: number) {
    const homepageSectionItem: HomepageSectionItem = {
      id: sectionItemId
    };

    return this.homepageSectionItemCUDService.removeSectionItem(homepageSectionItem)
  }

  editSectionItem(itemId: number, itemFileName: string, itemName: string, itemTarget: string, itemDisplayOrder: number, badgeKey: string) {
    const homepageSectionItem: HomepageSectionItem = {
      id: itemId,
      imageUrl: '' + itemFileName,
      name: itemName,
      targetUrl: itemTarget,
      displayOrder: itemDisplayOrder,
      badgeKey: badgeKey
    };

    return this.homepageSectionItemCUDService.editSectionItem(homepageSectionItem)
  }

  createCategory(name: string, parentId: any) {
    var homepageCategory: HomepageCategory = {}

    if (parentId === null) {
      homepageCategory = {
        name: name,
        displayOrder: 0,
      }
    } else {
      homepageCategory = {
        name: name,
        parentId: parseInt(parentId, 10),
        displayOrder: 0,
      }
    }

    return this.homepageCategoryCUDService.createCategory(homepageCategory)
  }

  deleteCategory(categoryId: number) {
    const category: HomepageCategory = {
      id: categoryId
    };

    return this.homepageCategoryCUDService.deleteCategory(category)
  }

  updateCategory(categoryId: any, name: string, parentId: any) {
    var homepageCategory: HomepageCategory = {}

    if (parentId === null) {
      homepageCategory = {
        id: parseInt(categoryId, 10),
        name: name,
        parentId: -1,
        displayOrder: 0,
      }
    } else {
      homepageCategory = {
        id: parseInt(categoryId, 10),
        name: name,
        parentId: parseInt(parentId, 10),
        displayOrder: 0,
      }
    }

    return this.homepageCategoryCUDService.updateCategory(homepageCategory)
  }
}
