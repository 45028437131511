import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomPopupService {

  private customPopupDisplayed = {
    "custom-popup-1": {
      alreadyDisplayed: false
    },
    "custom-popup-2": {
      alreadyDisplayed: false
    }
  }

  constructor() { }

  customPopupAlreadyDisplayed(popupKey: 'custom-popup-1' | 'custom-popup-2'): boolean {
    if (this.customPopupDisplayed[popupKey]) {
      return this.customPopupDisplayed[popupKey].alreadyDisplayed
    }

    return false
  }

  setCustomPopupAlreadyDisplayed(popupKey: 'custom-popup-1' | 'custom-popup-2'): void {
    if (this.customPopupDisplayed[popupKey]) {
      this.customPopupDisplayed[popupKey].alreadyDisplayed = true
    }
  }

  customPopupAlreadyInLocalStorage(popupKey: string): boolean {
    return window.localStorage.getItem(popupKey) === "true"
  }

  setCustomPopupInLocalStorage(popupKey: string): void {
    window.localStorage.setItem(popupKey, "true")
  }

  removeCustomPopupInLocalStorage(popupKey: string): void {
    window.localStorage.removeItem(popupKey)
  }
}
