<div class="tree-menu" [ngClass]="{ 'left-nav': isLeftNav }">
  <div class="content">
    <div class="actions" *ngIf="!isLeftNav">
      <button type="button" class="btn btn-1 menu-toggle">Done</button>
    </div>
    <div class="title-text" *ngIf="!isLeftNav">
      <div class="title">Menu</div>
    </div>
    <div class="mb-1" *ngIf="!isLeftNav">
      <div class="title-separator"></div>
    </div>
    <ul
      class="tree-menu-nav list-group"
      *ngIf="menuCategoriesData"
      [ngClass]="{ pbna: sharedService.environmentIsPBNA() }"
    >
      <li
        class="list-group-item chapter"
        *ngIf="sharedService.environmentIsMCOE()"
      >
        <button class="btn p-0" (click)="navigateToBugName('Special Offers')">
          <p class="ga-clic-bug-sidemenu" data-titlega="Special Offers">
            Special Offers
          </p>
        </button>
      </li>
      <li class="list-group-item chapter">
        <button class="btn p-0" (click)="navigateToCategoryId(-1)">
          <p class="ga-clic-product-sidemenu" data-titlega="Full Catalog">
            Full Catalog
          </p>
        </button>
      </li>
      <div *ngFor="let category1 of menuCategoriesData">
        <li class="list-group-item chapter">
          <a
            *ngIf="category1.children && category1.children.length > 0"
            data-toggle="collapse"
            [href]="'#category2-' + category1.id"
            aria-expanded="false"
            class="collapsed"
          >
            <span></span>
          </a>
          <button
            class="btn p-0"
            (click)="navigateToCategoryId(category1.id ?? -1)"
          >
            <p
              class="ga-clic-product-sidemenu"
              [attr.data-titlega]="category1.name"
            >
              {{ category1.name }}
            </p>
          </button>
        </li>
        <div class="collapse" [id]="'category2-' + category1.id">
          <div *ngFor="let category2 of category1.children">
            <li class="list-group-item header">
              <a
                *ngIf="category2.children && category2.children.length > 0"
                data-toggle="collapse"
                [href]="'#category3-' + category2.id"
                aria-expanded="false"
                class="collapsed"
              >
                <span></span>
              </a>
              <button
                class="btn p-0"
                (click)="navigateToCategoryId(category2.id ?? -1)"
              >
                <p
                  class="ga-clic-product-sidemenu"
                  [attr.data-titlega]="category2.name"
                >
                  {{ category2.name }}
                </p>
              </button>
            </li>
            <div class="collapse" [id]="'category3-' + category2.id">
              <div *ngFor="let category3 of category2.children">
                <li class="list-group-item subheader">
                  <button
                    class="btn p-0"
                    (click)="navigateToCategoryId(category3.id ?? -1)"
                  >
                    <p
                      class="ga-clic-product-sidemenu"
                      [attr.data-titlega]="category3.name"
                    >
                      {{ category3.name }}
                    </p>
                  </button>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div class="separator" *ngIf="!isLeftNav"></div>
      </div>
      <li
        class="list-group-item chapter"
        *ngIf="sharedService.environmentIsFSV()"
      >
        <button class="btn p-0" (click)="navigateToBugName('Accessories')">
          <p class="ga-clic-bug-sidemenu" data-titlega="Accessories">
            Accessories
          </p>
        </button>
      </li>
      <li class="list-group-item chapter" *ngIf="!isLeftNav">
        <button class="btn p-0 inventory" (click)="displayInventoryModal()">
          <p *ngIf="sharedService.environmentIsMCOE()">
            Inventory &<br />Sell Sheets
          </p>
          <p *ngIf="sharedService.environmentIsFSV()">
            Reports, Forms<br />& Sell Sheets
          </p>
        </button>
        <a
          class="btn-4 ga-clic-homepage switchDisplayStyleMobile"
          (click)="switchDisplayStyle()"
          data-titlega="Header - DisplayStyle"
        >
          <img
            *ngIf="isCardLayout"
            [src]="
              sharedService.environmentIsPBNA()
                ? 'assets/images/products/CardViewOnPBNA.png'
                : 'assets/images/products/CardViewOn.png'
            "
          />
          <img
            *ngIf="!isCardLayout"
            [src]="
              sharedService.environmentIsPBNA()
                ? 'assets/images/products/ListViewOnPBNA.png'
                : 'assets/images/products/ListViewOn.png'
            "
          />
        </a>
      </li>
      <li class="list-group-item chapter" *ngIf="!isLeftNav">
        <button class="btn p-0 inventory" (click)="openEPOP()">
          <p>EPOP</p>
        </button>
      </li>
    </ul>
  </div>
</div>
