import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Apollo, gql } from "apollo-angular";
import { UploadService } from "./upload.service";
import {
  AddBugInput,
  Bug as BugGQL,
  UpdateBugInput as UpdateBugInputGQL,
} from "../types/graphql/graphql.type";
import { Bug } from "../types/bug.type";
import { environment } from "src/environments/environment";

export type CreateBugInput = AddBugInput;
export type UpdateBugInput = UpdateBugInputGQL;

type BugsResponse = {
  bugs: BugGQL[];
};

type CreateBugResponse = {
  createBug: BugGQL;
};

type CreateBugVariables = {
  bug: AddBugInput;
};

type UpdateBugResponse = {
  updateBug: BugGQL;
};

type UpdateBugVariables = {
  bug: UpdateBugInputGQL;
};

type DeleteBugResponse = {
  deleteBug: boolean;
};

type DeleteBugVariables = {
  bugId: number;
};

@Injectable({
  providedIn: "root",
})
export class BugsService {
  private _apollo = inject(Apollo);
  private _uploadService = inject(UploadService);

  readonly IMAGE_UPLOAD_FOLDER: string = "bugs";

  private _staticFiles: string[] = [
    "accessories-logo.png",
    "ace-logo.png",
    "Bugs%20-%201.png",
    "Bugs%20-%202.png",
    "Bugs%20-%203.png",
    "Bugs%20-%204.png",
    "Bugs%20-%205.png",
    "Bugs%20-%20BigBets.png",
    "Bugs%20-%20C_G.png",
    "Bugs%20-%20Pepsi%20Re-Brand.png",
    "Bugs%20-%20SmallFormat.png",
    "headercards-logo.png",
    "mto-logo.png",
    "multipack-logo.png",
    "new-logo.png",
    "special-offers-logo.png",
    "usell-logo.png",
  ];

  private _GET_BUGS_QUERY = gql`
    query GetBugs {
      bugs {
        id
        name
        imageUrl
      }
    }
  `;

  getBugs(): Observable<Bug[]> {
    return this._apollo
      .watchQuery<BugsResponse>({
        query: this._GET_BUGS_QUERY,
      })
      .valueChanges.pipe(
        map(({ data: { bugs } }) => bugs.map((bug) => this.bugGQLtoBug(bug))),
      );
  }

  private bugGQLtoBug(bug: BugGQL): Bug {
    return {
      id: Number(bug.id), // Temporary until ID type is fixed in backend
      name: bug.name,
      image: this.parseImage(bug.imageUrl),
    };
  }

  private parseImage(name: string): string {
    if (this._staticFiles.includes(name))
      return `assets/images/products/bugs/${name}`;

    return `${environment.container}/assets/${this.IMAGE_UPLOAD_FOLDER}/${name}`;
  }

  uploadImage(file: File): Observable<string> {
    return this._uploadService.uploadAsset(file, this.IMAGE_UPLOAD_FOLDER)
      .upload$;
  }

  createBug(bugData: CreateBugInput): Observable<void> {
    return this._apollo
      .mutate<CreateBugResponse, CreateBugVariables>({
        mutation: gql`
          mutation CreateBug($bug: AddBugInput!) {
            createBug(bug: $bug) {
              id
              name
              imageUrl
            }
          }
        `,
        variables: { bug: bugData },
        update: (cache, { data }) => {
          const existingData = cache.readQuery<BugsResponse>({
            query: this._GET_BUGS_QUERY,
          });
          if (existingData && data?.createBug) {
            cache.writeQuery({
              query: this._GET_BUGS_QUERY,
              data: { bugs: [...existingData.bugs, data.createBug] },
            });
          }
        },
      })
      .pipe(map(({ data }) => {}));
  }

  updateBug(bugData: UpdateBugInput): Observable<void> {
    return this._apollo
      .mutate<UpdateBugResponse, UpdateBugVariables>({
        mutation: gql`
          mutation UpdateBug($bug: UpdateBugInput!) {
            updateBug(bug: $bug) {
              id
              imageUrl
              name
            }
          }
        `,
        variables: { bug: bugData },
      })
      .pipe(map(({ data }) => {}));
  }

  deleteBug(id: number): Observable<void> {
    return this._apollo
      .mutate<DeleteBugResponse, DeleteBugVariables>({
        mutation: gql`
          mutation DeleteBug($bugId: Int!) {
            deleteBug(bugId: $bugId)
          }
        `,
        variables: { bugId: id },
        update: (cache, { data }) => {
          if (data?.deleteBug) {
            const identity = cache.identify({ id, __typename: "Bug" });
            cache.evict({ id: identity, broadcast: true });
            cache.gc();
          }
        },
      })
      .pipe(
        map(({ data }) => {
          if (!data?.deleteBug) {
            throw "Error deleting Bug";
          }
        }),
      );
  }
}
