import { environment } from "src/environments/environment";
import { Product as ProductGQL } from "../types/graphql/graphql.type";
import {
  Product,
  PRODUCT_PROPERTIES_SCHEMA,
  PRODUCT_SELLSHEET,
  ProductCategory,
  ProductEditSearch,
  ProductEditSearchCategory,
  ProductImage,
  ProductProperties,
  ProductSellsheet,
} from "../types/product.type";
import { ZodError } from "zod";
import { fromError } from "zod-validation-error";

export function productGQLtoProductEditSearch(
  product: ProductGQL,
): ProductEditSearch {
  return {
    id: Number(product.id), // Temporary until ID type is fixed in backend
    name: product.name,
    eqi: product.eqi,
    thumbnail: product.image
      ? parseProductMainThumbnail(product.image)
      : undefined,
    categories: parseProductEditSearchCategories(product),
    bugs: product.bugs.map((b) => b.id),
    colors: product.productColors.map((pc) => pc.color.id),
  };
}

function parseProductMainThumbnail(image: string): string {
  let baseImageName: string = image;
  const extInd = image.lastIndexOf(".");
  if (extInd >= 0) {
    baseImageName = image.slice(0, extInd);
  }
  return `${environment.container}/images-products/thumbs/${baseImageName}x300.jpg`;
}

export function productGQLtoProduct(product: ProductGQL): Product {
  const properties = parseProductProperties(product);
  return {
    id: Number(product.id), // Temporary until ID type is fixed in backend
    name: product.name,
    sortId: product.sortId,
    eqi: product.eqi,
    uom: product.uom ?? undefined,
    depth: product.depth ?? undefined,
    width: product.width ?? undefined,
    height: product.height ?? undefined,
    size: product.size ?? undefined,
    metadata:
      product.metadata && product.metadata.length > 0
        ? product.metadata.split(",")
        : [],
    images: parseProductImages(product, properties),
    notes: product.notes ?? undefined,
    properties: properties,
    hasSellSheet: product.hasSellSheet,
    sellsheet: parseProductSellsheet(product),
    colors: product.productColors.map((c) => ({
      id: Number(c.color.id),
      fullEqi: c.fullEqi ?? undefined,
    })),
    bugs: product.bugs.map((b) => Number(b.id)),
    categories: parseProductCategories(product),
  };
}

function parseProductProperties(
  product: ProductGQL,
): ProductProperties | undefined {
  let properties: ProductProperties | undefined;
  try {
    const json = JSON.parse(product.properties ?? "{}");
    properties = PRODUCT_PROPERTIES_SCHEMA.parse(json);
  } catch (e) {
    if (e instanceof ZodError) {
      console.error(
        `Error parsing properties for product ${product.id}: ${fromError(e).message}`,
      );
    } else {
      console.error(`Error parsing properties for product ${product.id}`);
      console.error(e);
    }
  }

  return properties;
}

function parseProductImages(
  product: ProductGQL,
  properties?: ProductProperties,
): ProductImage[] {
  const images: ProductImage[] = [];
  if (environment.environmentId === "pbna") {
    if (product.image) {
      const imageBaseName = extractImageBasename(product.image);
      images.push({
        image: `${environment.container}/images-products/jpeg/${imageBaseName}.jpg`,
        smallThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x300.jpg`,
        largeThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x600.jpg`,
      });
    }

    if (properties?.images) {
      properties.images.map((image) => {
        const imageBaseName = extractImageBasename(image);
        images.push({
          image: `${environment.container}/images-products/jpeg/${imageBaseName}.jpg`,
          smallThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x300.jpg`,
          largeThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x600.jpg`,
        });
      });
    } else if (properties?.imagesCount) {
      for (let i = 1; i < properties.imagesCount; i++) {
        const ind = i + 1;
        images.push({
          image: `${environment.container}/images-products/jpeg/${product.eqi}-${ind}.jpg`,
          smallThumb: `${environment.container}/images-products/thumbs/${product.eqi}-${ind}x300.jpg`,
          largeThumb: `${environment.container}/images-products/thumbs/${product.eqi}-${ind}x600.jpg`,
        });
      }
    }
  } else {
    const imageBaseName: string =
      product.productColors[0]?.fullEqi ?? product.eqi;
    images.push({
      image: `${environment.container}/images-products/jpeg/${imageBaseName}.jpg`,
      smallThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x300.jpg`,
      largeThumb: `${environment.container}/images-products/thumbs/${imageBaseName}x600.jpg`,
    });
  }
  return images;
}

export function extractImageBasename(image: string): string {
  const startInd = Math.max(0, image.lastIndexOf("/") + 1);
  const endInd = image.lastIndexOf(".");
  return startInd >= 0 && endInd > startInd
    ? image.slice(startInd, endInd)
    : image;
}

export function extractImageExtension(image: string): string {
  const startInd = Math.max(0, image.lastIndexOf("."));
  return image.slice(startInd);
}

function parseProductSellsheet(
  product: ProductGQL,
): ProductSellsheet | undefined {
  let sellsheet: ProductSellsheet | undefined;
  try {
    const displaySpecJson = JSON.parse(product.sellSheet?.displaySpec ?? "{}");
    const economicsJson = JSON.parse(product.sellSheet?.economics ?? "{}");
    sellsheet = PRODUCT_SELLSHEET.parse({
      displaySpec: displaySpecJson,
      economics: economicsJson,
    });
  } catch (e) {
    if (e instanceof ZodError) {
      console.error(
        `Error parsing sellsheet for product ${product.id}: ${fromError(e).message}`,
      );
    } else {
      console.error(`Error parsing sellsheet for product ${product.id}`);
      console.error(e);
    }
  }

  return sellsheet;
}

function parseProductCategories(product: ProductGQL): ProductCategory[] {
  return product.categories.map((c) => {
    if (c.parent?.parent) {
      return {
        l1: c.parent.parent.id,
        l2: c.parent.id,
        l3: c.id,
      };
    } else if (c.parent) {
      return {
        l1: c.parent.id,
        l2: c.id,
      };
    }
    return {
      l1: c.id,
    };
  });
}

function parseProductEditSearchCategories(
  product: ProductGQL,
): ProductEditSearchCategory[] {
  return product.categories.map((c) => {
    if (c.parent?.parent) {
      return {
        l1: { id: c.parent.parent.id, name: c.parent.parent.name },
        l2: { id: c.parent.id, name: c.parent.name },
        l3: { id: c.id, name: c.name },
      };
    } else if (c.parent) {
      return {
        l1: { id: c.parent.id, name: c.parent.name },
        l2: { id: c.id, name: c.name },
      };
    }
    return {
      l1: { id: c.id, name: c.name },
    };
  });
}
