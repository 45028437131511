import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
  inject,
  signal,
} from "@angular/core";
import { ActivatedRoute, Router, Event, NavigationEnd } from "@angular/router";

import { DevicesService } from "src/app/core/services/devices.service";
// import { SearchService } from "src/app/core/services/search.service";
import { AdminService } from "src/app/admin/services/admin.service";
import {
  EventBrokerService,
  IEventListener,
} from "../../shared/services/eventBroker.service";

import { PublicService } from "../services/public.service";
import { Category as OldCategory } from "../services/category.service";
import { Product as OldProduct } from "../interfaces/product";
import { SearchResult } from "../interfaces/search-result";
import { Store } from "@ngrx/store";
import {
  IBreadCrumb,
  IBreadCrumbCategory,
} from "../../store/models/IBreadCrumb";
import { AppState } from "../../store/state/app.state";
import {
  ISiteSettings,
  DisplayStyle,
} from "src/app/store/models/ISiteSettings";

import { SharedService } from "src/app/shared/services/shared.service";
import { ProductProperties } from "../interfaces/product-properties";
import {
  catchError,
  delay,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
} from "rxjs";
import { setSiteSettingsBugsAction } from "src/app/store/actions/SiteSettings.actions";
import { setBreadCrumbAction } from "src/app/store/actions/BreadCrumb.actions";
import { DownloadComponent } from "./download/download.component";
import { ProductModalActionsComponent } from "../product-modal-actions/product-modal-actions.component";
import { FritolayProductViewerComponent } from "@gc/fritolay-product-viewer";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { HeadingComponent } from "../print/heading/heading.component";
import { CardComponent } from "./card/card.component";
import { HeaderComponent } from "./header/header.component";
import { SizeSpyDirective } from "../../shared/size-spy.directive";
import { MenuTreeComponent } from "../../shared/components/menu/menu-tree/menu-tree.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { NgIf, NgClass, NgFor } from "@angular/common";
import { CategoriesService } from "src/app/services/categories.service";
import { Category, CategoryWithProducts } from "src/app/types/category.type";
import { Product } from "src/app/types/product.type";
import { ProductsService } from "src/app/services/products.service";
import { SearchService } from "src/app/services/search.service";

declare var $: any;

class HeightAndWidth {
  height?: number;
  width?: number;
}

@Component({
  selector: "fc-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    BreadcrumbComponent,
    MenuTreeComponent,
    SizeSpyDirective,
    HeaderComponent,
    NgFor,
    CardComponent,
    HeadingComponent,
    NgxImageZoomModule,
    ProductModalActionsComponent,
    DownloadComponent,
    FritolayProductViewerComponent,
  ],
})
export class ProductComponent implements OnInit, OnDestroy {
  @Input() isPrint: boolean = false;

  categoriesData?: any[];
  bigBetsData: {
    category: "Flex" | "Brand";
    brand: string;
    sortId: number;
    products: OldProduct[];
  }[] = [];
  printNumberOfPages = 0;
  printBugHeadingPage1 = 0;
  printBugHeadingPage2 = 0;
  categoryDetails: any;
  categoryProducts: OldProduct[] = [];
  categoryProducts2: OldProduct[] = [];
  categoryChildren?: OldCategory[];
  bugReferenceName1 = "";
  bugReferenceName2 = "";
  isBugs: boolean = false;
  isBigBetsPage: boolean = false;
  isMobile: boolean = false;
  displayLoader: boolean = false;
  currentProductsSection = "product-section-0";
  currentCategoryName: string | null = null;
  currentCategoryChildName: string | null = null;
  currentCategoryChildChildName: string | null = null;
  isPrintFullCatalog: boolean = false;
  currentSearchType = 0;
  isAlreadyLoaded: boolean = false;
  pageHeight = 1580;
  headerHeight = 92;
  productsRowHeight = 412;
  todayString?: string;
  sellSheetStatusData = null;
  display360ProductViewerInThumbnailModal = false;
  thumbnailMagnification = 1;
  canListenMagnificationChange = false;
  thumbnailInterval?: number;
  canZoom = false;
  public isCardLayout = true;
  private sectionChangedListener: IEventListener;
  private loadedEventListener: IEventListener;
  private subscriptions = new Subscription();

  ////////////////////////////////////////////////////////////////////////
  private _categoriesService = inject(CategoriesService);
  private _productsService = inject(ProductsService);
  private _searchService = inject(SearchService);

  categoriesWithProducts = signal<CategoryWithProducts[]>([]);
  products = signal<Product[]>([]);

  thumbnailProduct: Product | null = null;
  thumbnailImage = "";
  thumbnailImageId: number = 0;
  thumbnailProductHas360 = false;

  downloadProduct: Product | null = null;
  downloadImageId: number = 0;

  private _updateCategoriesWithProducts = new Subject<Observable<void>>();
  private _updateProducts = new Subject<Observable<void>>();

  ////////////////////////////////////////////////////////////////////////

  constructor(
    private store: Store<AppState>,
    private eventBroker: EventBrokerService,
    private router: Router,
    private route: ActivatedRoute,
    private devicesService: DevicesService,
    private publicService: PublicService,
    // private searchService: SearchService,
    public sharedService: SharedService,
    public adminService: AdminService,
  ) {
    this.subscriptions.add(
      this._updateCategoriesWithProducts
        .pipe(switchMap((obs) => obs))
        .subscribe(),
    );
    this.subscriptions.add(
      this._updateProducts.pipe(switchMap((obs) => obs)).subscribe(),
    );

    this.subscriptions.add(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          if (this.isAlreadyLoaded) {
            this.loadProductsPage();
          }
        }
      }),
    );

    this.sectionChangedListener = eventBroker.listen<{}>(
      "sectionChanged",
      (data: {}) => this.onSectionChange(data),
    );

    this.store
      .select((state) => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.isCardLayout = s ? s.displayStyle === DisplayStyle.Cards : false;
        this.isBugs = s ? s.isBugs : false;
      });

    this.loadedEventListener = eventBroker.listen<void>("productsLoaded", () =>
      this.recomputeHeight(),
    );

    this.loadedEventListener = eventBroker.listen<void>(
      "productsContainerSizeChanged",
      () => this.recomputeHeight(),
    );
  }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile();
    this.isAlreadyLoaded = true;
    this.loadProductsPage();
  }

  public ngOnDestroy() {
    this.sectionChangedListener.ignore();
    this.loadedEventListener.ignore();
    this.subscriptions.unsubscribe();
  }

  private recomputeHeight(): void {
    let h = 0;
    $(".categoryContainer").each((index: any, value: any) => {
      h += $(value).height();
    });

    $(".catalog-content").css({
      height: `${h}px`,
    });
    $("#products-scroll-spy").css({
      height: `${h}px`,
    });
  }

  private generateCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    this.todayString = mm + "/" + dd + "/" + yyyy;
  }

  private loadProductsPage(): void {
    // this.adminService.getSettingByKey("pdf-sellSheet-status").subscribe(
    //   (settingByKeyData) => {
    //     var valueParsed = JSON.parse(settingByKeyData.value);

    //     if (
    //       valueParsed["status"] !== undefined &&
    //       valueParsed["status"] !== null
    //     ) {
    //       this.sellSheetStatusData = valueParsed;
    //     }
    //   },
    //   (error) => {
    //     console.error("there was an error sending the query", error);
    //   },
    // );

    this.categoryProducts = [];
    this.categoryProducts2 = [];

    if (this.isPrint) {
      document.getElementById("wrapper")!.style.height = "unset";

      this.generateCurrentDate();

      if (this.route.snapshot.paramMap.get("fullCatalog") === "full") {
        this.isPrintFullCatalog = true;
      } else {
        this.isPrintFullCatalog = false;
      }

      let categoriesIdsUrl = this.route.snapshot.paramMap.get("categoriesIds");
      var categoriesIds: any = [];
      let bugId = this.route.snapshot.paramMap.get("bugsIds");

      if (categoriesIdsUrl !== "null") {
        let categoriesIdsUrlSplit = categoriesIdsUrl?.split("&");

        categoriesIdsUrlSplit?.forEach((categoryId) => {
          let categoryIdToInt = parseInt(categoryId);

          categoriesIds.push(categoryIdToInt);
        });
      }

      this.getProductsByCategoriesAndBugs(categoriesIds, bugId ?? "");
    } else {
      if (this.route.snapshot.paramMap.get("type") === "categories") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: false }));

        if (this.route.snapshot.paramMap.get("objectId") === "all") {
          this._updateCategoriesWithProducts.next(
            this.getAllCategoriesProducts(),
          );
        } else {
          this._updateCategoriesWithProducts.next(
            this.getCategoryProducts(
              parseInt(this.route.snapshot.paramMap.get("objectId") ?? ""),
              // true,
              // () => {
              //   this.displayLoader = false;
              //   setTimeout(() => {
              //     this.eventBroker.emit<void>("productsLoaded", undefined);
              //   }, 500);
              // },
            ),
          );
        }
      } else if (this.route.snapshot.paramMap.get("type") === "bugs") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: true }));

        this.isBigBetsPage =
          this.sharedService.environmentIsPBNA() &&
          this.route.snapshot.paramMap.get("objectId") === "8";

        this._updateProducts.next(
          this.getProductsByBug(
            parseInt(this.route.snapshot.paramMap.get("objectId") ?? ""),
            // true,
            // () => {
            //   setTimeout(() => {
            //     this.eventBroker.emit<void>("productsLoaded", undefined);
            //   }, 500);
            // },
          ),
        );
      } else if (this.route.snapshot.paramMap.get("type") === "search") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: true }));
        this._updateProducts.next(
          this.getProductsByKeyword(
            decodeURIComponent(
              this.route.snapshot.paramMap.get("objectId") ?? "",
            ),
          ),
        );
      } else if (this.route.snapshot.paramMap.get("type") === "colors") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: true }));
        this._updateProducts.next(
          this.getProductsByColor(
            parseInt(this.route.snapshot.paramMap.get("objectId") ?? ""),
            // true,
            // () => {},
          ),
        );
      } else if (this.route.snapshot.paramMap.get("type") === "product") {
        this.displayLoader = true;
        this.store.dispatch(setSiteSettingsBugsAction({ isBugs: true }));
        this._updateProducts.next(
          this.getProductById(
            parseInt(this.route.snapshot.paramMap.get("objectId") ?? ""),
            // true,
            // () => {},
          ),
        );
      }
    }
  }

  private getAllCategoriesProducts(): Observable<void> {
    return this._categoriesService.getCategoriesWithProducts().pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of([]);
      }),
      map((categories) => {
        this.generateSingleBreadcrumb({ name: "Full Catalog", id: "all" });

        this.categoriesWithProducts.set(categories);
        this.currentCategoryName =
          categories.length > 0 ? categories[0].name : null;
        this.currentCategoryChildName =
          categories.length > 0 && categories[0].children.length > 0
            ? categories[0].children[0].name
            : null;
        this.currentCategoryChildChildName =
          categories.length > 0 &&
          categories[0].children.length > 0 &&
          categories[0].children[0].children.length > 0
            ? categories[0].children[0].children[0].name
            : null;

        this.scrollTop();

        this.displayLoader = false;
      }),
      delay(500),
      map(() => {
        this.eventBroker.emit<void>("productsLoaded", undefined);
      }),
    );

    // this.publicService.getAllCategoriesWithProducts().subscribe(
    //   (categoriesData) => {
    //     this.generateSingleBreadcrumb({ name: "Full Catalog", id: "all" });

    //     categoriesData.forEach((element) => {
    //       if (element.products && element.products.length > 0) {
    //         this.sort(element);
    //       }

    //       element.children?.forEach((elementChild) => {
    //         if (elementChild.products && elementChild.products.length > 0) {
    //           this.sort(elementChild);
    //         }

    //         elementChild.children?.forEach((elementChildChild) => {
    //           if (
    //             elementChildChild.products &&
    //             elementChildChild.products.length > 0
    //           ) {
    //             this.sort(elementChildChild);
    //           }
    //         });
    //       });
    //     });

    //     this.categoriesData = categoriesData;
    //     this.currentCategoryName = null;
    //     this.currentCategoryChildName = null;
    //     this.currentCategoryChildChildName = null;

    //     if (this.categoriesData.length > 0) {
    //       this.currentCategoryName = this.categoriesData[0]["name"];

    //       if (this.categoriesData[0]["children"].length > 0) {
    //         this.currentCategoryChildName =
    //           this.categoriesData[0]["children"][0]["name"];
    //       }
    //     }

    //     this.scrollTop();

    //     this.displayLoader = false;
    //     setTimeout(() => {
    //       this.eventBroker.emit<void>("productsLoaded", undefined);
    //     }, 500);

    //     _callback();
    //   },
    //   (error) => {
    //     this.displayLoader = false;
    //     console.error("there was an error sending the query", error);
    //   },
    // );
  }

  private getCategoryProducts(
    categoryId: number,
    // isSingleSearch: boolean,
    // _callback: any,
  ): Observable<void> {
    return this._categoriesService.getCategoryWithProducts(categoryId).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of(undefined);
      }),
      map((category) => {
        if (category) {
          this.currentCategoryName = category.name;
          this.currentCategoryChildName =
            category.children.length > 0 ? category.children[0].name : null;
          this.currentCategoryChildChildName =
            category.children.length > 0 &&
            category.children[0].children.length > 0
              ? category.children[0].children[0].name
              : null;

          this.displayLoader = false;
          this.categoriesWithProducts.set([category]);
          this.generateMultipleBreadcrumb(category);
          this.scrollTop();
        }
      }),
      delay(500),
      map(() => {
        this.eventBroker.emit<void>("productsLoaded", undefined);
      }),
    );
    // this.publicService.getCategoryWithProducts(categoryId).subscribe(
    //   (categoryData) => {
    //     if (isSingleSearch) {
    //       let categoryDataArray = [];
    //       categoryDataArray.push(categoryData);

    //       categoryDataArray.forEach((element) => {
    //         if (element.products && element.products.length > 0) {
    //           this.sort(element);
    //         }

    //         element.children?.forEach((elementChild) => {
    //           if (elementChild.products && elementChild.products.length > 0) {
    //             this.sort(elementChild);
    //           }

    //           elementChild.children?.forEach((elementChildChild) => {
    //             if (
    //               elementChildChild.products &&
    //               elementChildChild.products.length > 0
    //             ) {
    //               this.sort(elementChildChild);
    //             }
    //           });
    //         });
    //       });

    //       this.generateMultipleBreadcrumb(categoryData);
    //       this.categoriesData = categoryDataArray;

    //       this.currentCategoryName = null;
    //       this.currentCategoryChildName = null;
    //       this.currentCategoryChildChildName = null;

    //       if (this.categoriesData.length > 0) {
    //         this.currentCategoryName = this.categoriesData[0]["name"];

    //         if (this.categoriesData[0]["children"].length > 0) {
    //           this.currentCategoryChildName =
    //             this.categoriesData[0]["children"][0]["name"];
    //         }
    //       }

    //       this.scrollTop();

    //       _callback();
    //     } else {
    //       this.categoryProducts.push(...(categoryData.products ?? []));

    //       categoryData["children"]?.forEach((categoryDataChildren) => {
    //         if (
    //           categoryDataChildren.products &&
    //           categoryDataChildren.products.length > 0
    //         ) {
    //           this.categoryProducts.push(...categoryDataChildren.products);
    //         }

    //         categoryDataChildren["children"]?.forEach(
    //           (categoryDataChildrenChildren) => {
    //             if (
    //               categoryDataChildrenChildren.products &&
    //               categoryDataChildrenChildren.products.length > 0
    //             ) {
    //               this.categoryProducts.push(
    //                 ...categoryDataChildrenChildren.products,
    //               );
    //             }
    //           },
    //         );
    //       });

    //       _callback();
    //     }
    //   },
    //   (error) => {
    //     this.displayLoader = false;
    //     console.error("there was an error sending the query", error);
    //   },
    // );
  }

  private getProductsByCategoriesAndBugs(
    categoriesIds: number[],
    bugId: string,
  ): void {
    let previousHeadingName = "";

    if (categoriesIds.length > 0) {
      const publicSubscription = this._categoriesService
        .getCategoriesWithProducts(categoriesIds)
        .subscribe((categories) => {
          // Flat all categories into one array with specifics types
          // Group products by array of 3 objects (representing 1 row)
          let printData: any[] = [];
          categories.forEach((l1Category) => {
            let headingName =
              l1Category.parent?.parent?.name ??
              l1Category.parent?.name ??
              l1Category.name;

            if (headingName !== previousHeadingName) {
              printData.push({
                type: "heading",
                height: this.pageHeight,
                name: headingName,
                firstParentName: l1Category.name,
              });
            }

            previousHeadingName = headingName;

            generateHeaderAndProductsArray(
              l1Category,
              null,
              null,
              this.headerHeight,
              this.productsRowHeight,
            );
            l1Category.children.forEach((l2Category: any) => {
              generateHeaderAndProductsArray(
                l2Category,
                l1Category,
                null,
                this.headerHeight,
                this.productsRowHeight,
              );
              l2Category.children.forEach((l3Category: any) => {
                generateHeaderAndProductsArray(
                  l3Category,
                  l2Category,
                  l1Category,
                  this.headerHeight,
                  this.productsRowHeight,
                );
              });
            });

            function sort(element: CategoryWithProducts): CategoryWithProducts {
              return {
                ...element,
                products: [...element.products].sort(
                  (a, b) => a.sortId - b.sortId,
                ),
              };
            }

            function generateHeaderAndProductsArray(
              element: CategoryWithProducts,
              elementParent: CategoryWithProducts | null,
              elementParentParent: CategoryWithProducts | null,
              headerHeight: number,
              productsRowHeight: number,
            ) {
              element = sort(element);

              let categoryName: string = "";
              let categoryChildName: string | null = "";
              let categoryChildChildName: string | null = "";

              if (elementParentParent != null && elementParent !== null) {
                categoryName = elementParentParent.name;
                categoryChildName = elementParent.name;
                categoryChildChildName = element.name;
              } else if (
                elementParentParent == null &&
                elementParent !== null
              ) {
                categoryName = elementParent.name;
                categoryChildName = element.name;
                categoryChildChildName = null;
              } else {
                categoryName = element.name;
                categoryChildName = null;
                categoryChildChildName = null;
              }

              if (element.products.length > 0) {
                printData.push({
                  type: "header",
                  height: headerHeight,
                  id: element.id,
                  name: element.name,
                  categoryName: categoryName,
                  categoryChildName: categoryChildName,
                  categoryChildChildName: categoryChildChildName,
                });

                const products = [...element.products];
                while (products.length) {
                  printData.push({
                    type: "products",
                    height: productsRowHeight,
                    data: products.splice(0, 3),
                  });
                }
              }
            }

            // Group header and first line of row together into 1 block
            const printDataClone = [...printData];
            var printDataGroup = [];

            var i = 0;
            while (i < printDataClone.length) {
              let currentObject = printDataClone[i];

              if (currentObject["type"] === "header") {
                printDataGroup.push({
                  type: "headerAndProducts",
                  height: this.headerHeight + this.productsRowHeight,
                  data: printDataClone.slice(i, i + 2),
                });

                i++;
              } else {
                printDataGroup.push(printDataClone.slice(i, i + 1)[0]);
              }

              i++;
            }

            // Calculate height for each elements and generate appropiate pages
            this.calculElementsHeightAndGeneratePages(
              "products",
              printDataGroup,
              0,
              (printDataPage: any, currentPage: any) => {
                this.categoriesData = printDataPage;
                this.printNumberOfPages = currentPage;

                this.generateBugsData(bugId, publicSubscription, currentPage);
              },
            );
          });
        });

      // const publicSubscription = this.publicService
      //   .getProductsByCategories(categoriesIds)
      //   .subscribe(
      //     (getCategoriesWithProductsData) => {
      //       // Flat all categories into one array with specifics types
      //       // Group products by array of 3 objects (representing 1 row)
      //       var printData: any[] = [];
      //       var getCategoriesWithProductsDataSorted: any[] = [];

      //       categoriesIds.forEach((el) =>
      //         getCategoriesWithProductsDataSorted.push(
      //           getCategoriesWithProductsData.find((e) => e.id == el),
      //         ),
      //       );

      //       getCategoriesWithProductsDataSorted.forEach((firstParent) => {
      //         var headingName = firstParent.name;

      //         if (firstParent.parent) {
      //           headingName = firstParent.parent.name;

      //           if (firstParent.parent.parent) {
      //             headingName = firstParent.parent.parent.name;
      //           }
      //         }

      //         if (headingName !== previousHeadingName) {
      //           printData.push({
      //             type: "heading",
      //             height: this.pageHeight,
      //             name: headingName,
      //             firstParentName: firstParent.name,
      //           });
      //         }

      //         previousHeadingName = headingName;

      //         generateHeaderAndProductsArray(
      //           firstParent,
      //           null,
      //           null,
      //           this.headerHeight,
      //           this.productsRowHeight,
      //         );
      //         firstParent["children"].forEach((secondParent: any) => {
      //           generateHeaderAndProductsArray(
      //             secondParent,
      //             firstParent,
      //             null,
      //             this.headerHeight,
      //             this.productsRowHeight,
      //           );
      //           secondParent["children"].forEach((thirdParent: any) => {
      //             generateHeaderAndProductsArray(
      //               thirdParent,
      //               secondParent,
      //               firstParent,
      //               this.headerHeight,
      //               this.productsRowHeight,
      //             );
      //           });
      //         });
      //       });

      //       function sort(element: any) {
      //         if (element.products.length > 0) {
      //           element.products.sort(function (a: any, b: any) {
      //             return a.sortId - b.sortId;
      //           });
      //         }
      //       }

      //       function generateHeaderAndProductsArray(
      //         element: any,
      //         elementParent: any,
      //         elementParentParent: any,
      //         headerHeight: any,
      //         productsRowHeight: any,
      //       ) {
      //         sort(element);

      //         var categoryName: string = "";
      //         var categoryChildName: string | null = "";
      //         var categoryChildChildName: string | null = "";

      //         if (elementParentParent != null && elementParent !== null) {
      //           categoryName = elementParentParent.name;
      //           categoryChildName = elementParent.name;
      //           categoryChildChildName = element.name;
      //         } else if (
      //           elementParentParent == null &&
      //           elementParent !== null
      //         ) {
      //           categoryName = elementParent.name;
      //           categoryChildName = element.name;
      //           categoryChildChildName = null;
      //         } else {
      //           categoryName = element.name;
      //           categoryChildName = null;
      //           categoryChildChildName = null;
      //         }

      //         if (element.products.length > 0) {
      //           printData.push({
      //             type: "header",
      //             height: headerHeight,
      //             id: element.id,
      //             name: element.name,
      //             categoryName: categoryName,
      //             categoryChildName: categoryChildName,
      //             categoryChildChildName: categoryChildChildName,
      //           });

      //           while (element.products.length) {
      //             printData.push({
      //               type: "products",
      //               height: productsRowHeight,
      //               data: element.products.splice(0, 3),
      //             });
      //           }
      //         }
      //       }

      //       // Group header and first line of row together into 1 block

      //       const printDataClone = [...printData];
      //       var printDataGroup = [];

      //       var i = 0;
      //       while (i < printDataClone.length) {
      //         let currentObject = printDataClone[i];

      //         if (currentObject["type"] === "header") {
      //           printDataGroup.push({
      //             type: "headerAndProducts",
      //             height: this.headerHeight + this.productsRowHeight,
      //             data: printDataClone.slice(i, i + 2),
      //           });

      //           i++;
      //         } else {
      //           printDataGroup.push(printDataClone.slice(i, i + 1)[0]);
      //         }

      //         i++;
      //       }

      //       // Calculate height for each elements and generate appropiate pages

      //       this.calculElementsHeightAndGeneratePages(
      //         "products",
      //         printDataGroup,
      //         0,
      //         (printDataPage: any, currentPage: any) => {
      //           this.categoriesData = printDataPage;
      //           this.printNumberOfPages = currentPage;

      //           this.generateBugsData(bugId, publicSubscription, currentPage);
      //         },
      //       );
      //     },
      //     (error) => {
      //       console.error("there was an error sending the query", error);
      //     },
      //   );
    } else {
      this.generateBugsData(bugId, null, 0);
    }
  }

  private generateBugsData(bugId: any, subscription: any, pageNumber: any) {
    if (bugId !== null) {
      var bugReferenceName1: string | null = null;
      var bugProductsId1: number | null = null;
      var bugReferenceName2: string | null = null;
      var bugProductsId2: number | null = null;

      switch (bugId) {
        case "special-offers":
          bugReferenceName1 = "Special Offers";
          bugProductsId1 = 4;
          break;
        case "header-cards":
          bugReferenceName1 = "Header Cards";
          bugProductsId1 = 4;
          break;
        case "accessories":
          bugReferenceName1 = "Accessories";
          bugProductsId1 = 3;
          break;
        case "accessories&header-cards":
          bugReferenceName1 = "Accessories";
          bugProductsId1 = 3;
          bugReferenceName2 = "Header Cards";
          bugProductsId2 = 4;
          break;
        case "header-cards&accessories":
          bugReferenceName1 = "Header Cards";
          bugProductsId1 = 4;
          bugReferenceName2 = "Accessories";
          bugProductsId2 = 3;
          break;
      }

      if (bugReferenceName1 !== null && bugProductsId1 !== null) {
        if (subscription !== null) {
          console.log("unsubscribe", subscription);
          subscription.unsubscribe();
        }

        this.publicService.getBugProducts(bugProductsId1).subscribe(
          (bugData) => {
            this.groupBugsBy3(
              bugData,
              pageNumber,
              "bug1",
              (currentPage: any) => {
                this.bugReferenceName1 = bugReferenceName1 ?? "";

                if (bugReferenceName2 !== null && bugProductsId2 !== null) {
                  this.publicService.getBugProducts(bugProductsId2).subscribe(
                    (bugData) => {
                      this.groupBugsBy3(
                        bugData,
                        currentPage,
                        "bug2",
                        (currentPage: any) => {
                          this.bugReferenceName2 = bugReferenceName2 ?? "";
                        },
                      );
                    },
                    (error) => {
                      console.error(
                        "there was an error sending the query",
                        error,
                      );
                    },
                  );
                }
              },
            );
          },
          (error) => {
            console.error("there was an error sending the query", error);
          },
        );
      }
    }
  }

  private groupBugsBy3(
    bugData: any,
    page: any,
    bugsNumber: any,
    _callback: any,
  ) {
    const bugDataClone = [...bugData];
    var printBugsData = [];

    while (bugDataClone.length) {
      printBugsData.push({
        type: "products",
        height: this.productsRowHeight,
        data: bugDataClone.splice(0, 3),
      });
    }

    this.calculElementsHeightAndGeneratePages(
      bugsNumber,
      printBugsData,
      page,
      (printDataPage: any, currentPage: any) => {
        if (bugsNumber === "bug1") {
          this.categoryProducts = printDataPage;
        } else if (bugsNumber === "bug2") {
          this.categoryProducts2 = printDataPage;
        }

        this.printNumberOfPages = currentPage;

        _callback(currentPage);
      },
    );
  }

  private calculElementsHeightAndGeneratePages(
    itemType: any,
    printDataGroup: any,
    page: any,
    _callback: any,
  ) {
    var printDataPage = [];
    var currentHeight = 0;
    var currentPage = page;

    if (itemType === "bug1") {
      currentPage = page += 1;
      this.printBugHeadingPage1 = currentPage;
      generateHeader(null);
    } else if (itemType === "bug2") {
      currentPage = page += 1;
      this.printBugHeadingPage2 = currentPage;
      generateHeader(null);
    }

    for (let index = 0; index < printDataGroup.length; index++) {
      const element = printDataGroup[index];

      if (element["type"] === "heading") {
        generateHeader(element);
        currentPage++;

        printDataPage.push({
          type: "header",
          value: currentPage,
        });
      } else {
        if (currentHeight + element["height"] + 50 <= this.pageHeight) {
          printDataPage.push(element);

          currentHeight += element["height"];
        } else {
          generateHeader(element);
          currentHeight += element["height"];
        }
      }
    }

    function generateHeader(element: any) {
      currentPage++;

      printDataPage.push({
        type: "header",
        value: currentPage,
      });

      if (element !== null) {
        printDataPage.push(element);
      }

      currentHeight = 0;
    }

    _callback(printDataPage, currentPage);
  }

  private getProductsByBug(
    bugId: number,
    // isSingleSearch: boolean,
    // _callback: any,
  ): Observable<void> {
    return this._productsService.getProductsByBug(bugId).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of([]);
      }),
      map((products) => {
        this.displayLoader = false;
        this.products.set(products);
        this.scrollTop();
      }),
      delay(500),
      map(() => {
        this.eventBroker.emit<void>("productsLoaded", undefined);
      }),
    );
    // this.publicService.getBugProducts(bugId).subscribe(
    //   (bugData) => {
    //     if (isSingleSearch) {
    //       this.categoryProducts = bugData;
    //       this.scrollTop();
    //       this.displayLoader = false;
    //       //console.log('bugdata', bugData)
    //       if (this.isBigBetsPage) {
    //         this.generateBigBetsData();
    //       }
    //       _callback();
    //     } else {
    //       this.categoryProducts.push(...bugData);
    //       this.displayLoader = false;
    //       _callback();
    //     }
    //   },
    //   (error) => {
    //     this.displayLoader = false;
    //     console.error("there was an error sending the query", error);
    //   },
    // );
  }

  // private generateBigBetsData(): void {
  //   const sortCategories: string[] = [
  //     "flex",
  //     "pepsi",
  //     "starry",
  //     "mountain dew",
  //     "gatorade",
  //     "rockstar",
  //     "energy portfolio",
  //     "starbucks",
  //   ];
  //   this.categoryProducts.map((product) => {
  //     const brand = JSON.parse(product.properties).brand;
  //     //console.log("brand", brand);
  //     if (!this.bigBetsData.find((data) => data.brand === brand)) {
  //       const sortIndex: number =
  //         typeof brand === "string"
  //           ? sortCategories.findIndex((v) => v === brand.toLowerCase())
  //           : -1;
  //       this.bigBetsData.push({
  //         category: brand === "Flex" ? "Flex" : "Brand",
  //         brand: brand,
  //         sortId: sortIndex >= 0 ? sortIndex : sortCategories.length,
  //         products: this.categoryProducts.filter(
  //           (categoryProduct) =>
  //             brand === JSON.parse(categoryProduct.properties).brand,
  //         ),
  //       });
  //     }
  //   });
  //   this.bigBetsData.sort((a, b) => a.sortId - b.sortId);
  //   //console.log(this.bigBetsData);
  //   this.categoriesData = [
  //     {
  //       id: -1,
  //       name: "WAVE 2 BIG BETS",
  //       parent: null,
  //       products: [],
  //       children: this.bigBetsData.map((data) => ({
  //         id: -1,
  //         name: data.brand,
  //         products: data.products,
  //         children: [],
  //       })),
  //     },
  //   ];
  // }

  private getProductsByKeyword(
    keyword: string,
    // isSingleSearch: boolean,
    // _callback: any,
  ): Observable<void> {
    return this._searchService.searchProduct(keyword).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of([]);
      }),
      map((products) => {
        this.displayLoader = false;
        this.products.set(products);
        this.scrollTop();
      }),
      delay(500),
      map(() => {
        this.eventBroker.emit<void>("productsLoaded", undefined);
      }),
    );
    // this.publicService.getProductsByKeyword(keyword).subscribe(
    //   (productsByKeywordData) => {
    //     if (isSingleSearch) {
    //       this.categoryProducts = productsByKeywordData;
    //       this.scrollTop();
    //       this.displayLoader = false;

    //       _callback();
    //     } else {
    //       this.categoryProducts.push(...productsByKeywordData);
    //       this.displayLoader = false;

    //       _callback();
    //     }
    //   },
    //   (error) => {
    //     this.displayLoader = false;
    //     console.error("there was an error sending the query", error);
    //   },
    // );
  }

  private getProductsByColor(
    colorId: number,
    // isSingleSearch: boolean,
    // _callback: any,
  ): Observable<void> {
    return this._productsService.getProductsByColor(colorId).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of([]);
      }),
      map((products) => {
        this.displayLoader = false;
        this.products.set(products);
        this.scrollTop();
      }),
      // delay(500),
      // map(() => {
      //   this.eventBroker.emit<void>("productsLoaded", undefined);
      // }),
    );
    // this.publicService.getProductsByColor(colorId).subscribe(
    //   (productsByColorData) => {
    //     if (isSingleSearch) {
    //       this.categoryProducts = productsByColorData;
    //       this.scrollTop();
    //       this.displayLoader = false;

    //       _callback();
    //     } else {
    //       this.categoryProducts.push(...productsByColorData);
    //       this.displayLoader = false;

    //       _callback();
    //     }
    //   },
    //   (error) => {
    //     this.displayLoader = false;
    //     console.error("there was an error sending the query", error);
    //   },
    // );
  }

  private getProductById(
    productId: number,
    // isSingleSearch: boolean,
    // _callback: any,
  ): Observable<void> {
    return this._productsService.getProduct(productId).pipe(
      catchError((error) => {
        console.error("Error querying products", error);
        return of(undefined);
      }),
      map((product) => {
        this.displayLoader = false;
        this.products.set(product ? [product] : []);
        this.scrollTop();
      }),
      // delay(500),
      // map(() => {
      //   this.eventBroker.emit<void>("productsLoaded", undefined);
      // }),
    );
    // this.publicService.getProductByid(productId).subscribe(
    //   (productByIdData) => {
    //     var productByIdDataArray = [];
    //     productByIdDataArray.push(productByIdData);

    //     if (isSingleSearch) {
    //       this.categoryProducts = productByIdDataArray;
    //       this.scrollTop();
    //       this.displayLoader = false;

    //       _callback();
    //     } else {
    //       this.categoryProducts.push(...productByIdDataArray);
    //       this.displayLoader = false;

    //       _callback();
    //     }
    //   },
    //   (error) => {
    //     this.displayLoader = false;
    //     console.error("there was an error sending the query", error);
    //   },
    // );
  }

  // private searchByKeyword(searchKeyword: string) {
  //   // this.searchService.searchTerm(searchKeyword).subscribe(
  //   //   (searchResults) => {
  //   //     /*var searchResultsFiltered = searchResults.map(searchResult => searchResult)
  //   //       .filter((value, index, self) => value.type !== 'Product')*/

  //   //     this.currentSearchType = 0;
  //   //     this.scrollTop();
  //   //     this.loopOverSearchTypes(searchResults);
  //   //   },
  //   //   (error) => {
  //   //     console.error("there was an error sending the search", error);
  //   //   },
  //   // );
  // }

  // private loopOverSearchTypes(searchResultsTypes: SearchResult[]) {
  //   // if (searchResultsTypes.length > 0) {
  //   //   if (searchResultsTypes[this.currentSearchType].type === "Category") {
  //   //     this.getCategoryProducts(
  //   //       searchResultsTypes[this.currentSearchType].id,
  //   //       false,
  //   //       () => {
  //   //         this.checkNextSearchType(searchResultsTypes);
  //   //       },
  //   //     );
  //   //   } else if (searchResultsTypes[this.currentSearchType].type === "Bug") {
  //   //     this.getBugProducts(
  //   //       searchResultsTypes[this.currentSearchType].id,
  //   //       false,
  //   //       () => {
  //   //         this.checkNextSearchType(searchResultsTypes);
  //   //       },
  //   //     );
  //   //   } else if (searchResultsTypes[this.currentSearchType].type === "Color") {
  //   //     this.getProductsByColor(
  //   //       searchResultsTypes[this.currentSearchType].id,
  //   //       false,
  //   //       () => {
  //   //         this.checkNextSearchType(searchResultsTypes);
  //   //       },
  //   //     );
  //   //   } else if (
  //   //     searchResultsTypes[this.currentSearchType].type === "Product"
  //   //   ) {
  //   //     this.getProductById(
  //   //       searchResultsTypes[this.currentSearchType].id,
  //   //       false,
  //   //       () => {
  //   //         this.checkNextSearchType(searchResultsTypes);
  //   //       },
  //   //     );
  //   //   }
  //   // } else {
  //   //   this.checkNextSearchType([]);
  //   // }
  //   // setTimeout(() => {
  //   //   this.eventBroker.emit<void>("productsLoaded", undefined);
  //   // }, 500);
  // }

  // private checkNextSearchType(searchResultsTypes: SearchResult[]) {
  //   if (searchResultsTypes[this.currentSearchType + 1] !== undefined) {
  //     this.currentSearchType++;

  //     this.loopOverSearchTypes(searchResultsTypes);
  //   } else {
  //     this.getProductsByKeyword(
  //       decodeURIComponent(this.route.snapshot.paramMap.get("objectId") ?? ""),
  //       false,
  //       () => {
  //         this.categoryProducts = this.categoryProducts.filter(
  //           (value, index, self) =>
  //             self.findIndex((product) => product.id === value.id) === index,
  //         );

  //         $(".search-button-image").css("display", "block");
  //         $(".search-spinner").css("display", "none");
  //       },
  //     );
  //   }
  // }

  private generateSingleBreadcrumb(category: IBreadCrumbCategory): void {
    const breadCrumb: IBreadCrumb = {
      category: category,
      categoryChild: null,
      categoryChildChild: null,
    };

    this.store.dispatch(setBreadCrumbAction(breadCrumb));
  }

  // private generateMultipleBreadcrumb(categoryData: any): void {
  //   const breadCrumb: IBreadCrumb = {
  //     category: null,
  //     categoryChild: null,
  //     categoryChildChild: null,
  //   };

  //   if (categoryData.parent === null) {
  //     breadCrumb.category = categoryData;
  //   } else {
  //     if (categoryData.parent) {
  //       if (categoryData.parent.parent !== null) {
  //         breadCrumb.categoryChildChild = categoryData;
  //         breadCrumb.categoryChild = {
  //           id: categoryData.parent.id.toString(),
  //           name: categoryData.parent.name,
  //           parent: categoryData.parent.parent,
  //         };
  //         breadCrumb.category = {
  //           id: categoryData.parent.parent.id.toString(),
  //           name: categoryData.parent.parent.name,
  //           parent: categoryData.parent.parent.parent,
  //         };
  //       } else {
  //         breadCrumb.categoryChild = categoryData;
  //         breadCrumb.category = {
  //           id: categoryData.parent.id.toString(),
  //           name: categoryData.parent.name,
  //           parent: categoryData.parent.parent,
  //         };
  //       }
  //     } else {
  //       breadCrumb.category = categoryData;
  //     }
  //   }

  //   this.store.dispatch(setBreadCrumbAction(breadCrumb));
  // }

  private generateMultipleBreadcrumb(category: CategoryWithProducts): void {
    this._categoriesService.getCategories().subscribe((categories) => {
      for (const l1Category of categories) {
        if (l1Category.id === category.id) {
          this.store.dispatch(
            setBreadCrumbAction({
              category: { id: l1Category.id, name: l1Category.name },
              categoryChild: null,
              categoryChildChild: null,
            }),
          );
          return;
        }

        for (const l2Category of l1Category.children) {
          if (l2Category.id === category.id) {
            this.store.dispatch(
              setBreadCrumbAction({
                category: { id: l1Category.id, name: l1Category.name },
                categoryChild: { id: l2Category.id, name: l2Category.name },
                categoryChildChild: null,
              }),
            );
            return;
          }

          for (const l3Category of l2Category.children) {
            if (l3Category.id === category.id) {
              this.store.dispatch(
                setBreadCrumbAction({
                  category: { id: l1Category.id, name: l1Category.name },
                  categoryChild: { id: l2Category.id, name: l2Category.name },
                  categoryChildChild: {
                    id: l3Category.id,
                    name: l3Category.name,
                  },
                }),
              );
              return;
            }
          }
        }
      }
    });

    // const breadCrumb: IBreadCrumb = {
    //   category: null,
    //   categoryChild: null,
    //   categoryChildChild: null,
    // };
    // if (categoryData.parent === null) {
    //   breadCrumb.category = categoryData;
    // } else {
    //   if (categoryData.parent) {
    //     if (categoryData.parent.parent !== null) {
    //       breadCrumb.categoryChildChild = categoryData;
    //       breadCrumb.categoryChild = {
    //         id: categoryData.parent.id.toString(),
    //         name: categoryData.parent.name,
    //         parent: categoryData.parent.parent,
    //       };
    //       breadCrumb.category = {
    //         id: categoryData.parent.parent.id.toString(),
    //         name: categoryData.parent.parent.name,
    //         parent: categoryData.parent.parent.parent,
    //       };
    //     } else {
    //       breadCrumb.categoryChild = categoryData;
    //       breadCrumb.category = {
    //         id: categoryData.parent.id.toString(),
    //         name: categoryData.parent.name,
    //         parent: categoryData.parent.parent,
    //       };
    //     }
    //   } else {
    //     breadCrumb.category = categoryData;
    //   }
    // }

    // this.store.dispatch(setBreadCrumbAction(breadCrumb));
  }

  onSectionChange(currentSectionData: any) {
    this.currentProductsSection = currentSectionData["sectionId"];
    this.currentCategoryName = currentSectionData["categoryName"];
    this.currentCategoryChildName = currentSectionData["categoryChildName"];
    this.currentCategoryChildChildName =
      currentSectionData["categoryChildChildName"];
  }

  onDownloadCardClicked(product: Product, imageId: number = 0) {
    this.downloadProduct = product;
    this.downloadImageId = imageId;

    setTimeout(() => {
      $("#black-overlay").addClass("visible");
      $("#download-modal").modal("show");
    }, 500);
  }

  onDownloadModalClicked(): void {
    if (this.thumbnailProduct) {
      this.onDownloadCardClicked(this.thumbnailProduct, this.thumbnailImageId);
    }
  }

  onThumbnailClicked(product: Product) {
    this.thumbnailProduct = product;
    this.thumbnailProductHas360 = product.properties?.has360View ?? false;
    this.thumbnailImageId = 0;
    this.thumbnailImage =
      product.images.length > 0
        ? product.images[0].image
        : "assets/images/common/no_image.svg";
    setTimeout(() => {
      $("#black-overlay").addClass("visible");
      $("#thumbnail-modal").modal("show");
      this.thumbnailMagnification = 1.5;
      this.canListenMagnificationChange = true;
      const modalBodyElement = document.querySelector(
        "#thumbnail-modal .modal-body",
      ) as HTMLElement;
      const ngxImageZoomFullElement = document.querySelector(
        ".ngxImageZoomFull",
      ) as HTMLImageElement;
      if (!ngxImageZoomFullElement || !modalBodyElement) {
        return;
      }
      this.thumbnailInterval = window.setInterval(() => {
        if (
          modalBodyElement.offsetHeight !== 0 &&
          ngxImageZoomFullElement.height !== 0
        ) {
          clearInterval(this.thumbnailInterval);
          this.thumbnailMagnification =
            modalBodyElement.offsetHeight / ngxImageZoomFullElement.height +
            0.5;
          this.canZoom = true;
        }
      }, 500);
    }, 500);
  }

  closeThumbnailModal() {
    $("#black-overlay").removeClass("visible");
    $("#thumbnail-modal").modal("hide");
    this.closeFritolayProductViewer();
    this.thumbnailProduct = null;
    this.canListenMagnificationChange = false;
    this.canZoom = false;
    if (this.thumbnailInterval) {
      clearInterval(this.thumbnailInterval);
    }
  }

  private scrollTop() {
    setTimeout(() => {
      $(".container.content").scrollTop(0);

      if (document.getElementById("products-scroll-spy") !== null) {
        document.getElementById("products-scroll-spy")?.scrollBy(0, 1);
        $(".main-container").scrollTop(1);
      }
    }, 0);
  }

  // private sort(element: any) {
  //   if (element.products.length > 0) {
  //     element.products.sort(function (a: any, b: any) {
  //       return a.sortId - b.sortId;
  //     });
  //   }
  // }

  button360ClickedEmitter(): void {
    if (!this.display360ProductViewerInThumbnailModal) {
      this.display360ProductViewerInThumbnailModal = true;

      if (!document.getElementById("button-360")) {
        return;
      }
      document.getElementById("button-360")?.classList.add("active");
    } else {
      this.closeFritolayProductViewer();
    }
  }

  closeFritolayProductViewer(): void {
    this.display360ProductViewerInThumbnailModal = false;

    if (!document.getElementById("button-360")) {
      return;
    }
    document.getElementById("button-360")?.classList.remove("active");
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (this.canListenMagnificationChange) {
      const modalBodyElement = document.querySelector(
        "#thumbnail-modal .modal-body",
      ) as HTMLElement;
      const ngxImageZoomFullElement = document.querySelector(
        ".ngxImageZoomFull",
      ) as HTMLImageElement;

      if (!ngxImageZoomFullElement || !modalBodyElement) {
        return;
      }

      this.thumbnailMagnification =
        modalBodyElement.offsetHeight / ngxImageZoomFullElement.height + 0.5;
    }
  }

  thumbnailModalItemChange(id: number): void {
    this.thumbnailImageId = id;
    this.thumbnailImage = this.thumbnailProduct?.images[id].image ?? "";
  }
}
