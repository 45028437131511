import { Injectable } from '@angular/core';

import { Query, gql } from 'apollo-angular';

export interface CategoryL1 {
  id: number;
  name: string;
  children: CategoryL1[];
}

export interface CategoriesL1 {
  categories: CategoryL1[];
}

@Injectable({
  providedIn: 'root'
})
export class CategoriesL1Service extends Query<CategoriesL1> {
  override document = gql`
    query {
	    categories {id, name, children{id, name, children{id, name}}}      
    }`;
}
