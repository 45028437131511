import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query, gql } from 'apollo-angular';

export interface ProductsByBug {
  productsByBug: Product[];
}

@Injectable({
  providedIn: 'root'
})
export class BugService extends Query<ProductsByBug> {
  override document = gql`
    query productsByBug($bugId: Int!) {
      productsByBug(bugId: $bugId) {
        id
        sortId
        eqi
        name
        image
        width
        height
        depth
        uom
        notes
        productColors {
          fullEqi
          color {
            id
            code
            name
            image
          }
        }
        bugs {
          id
          name
          imageUrl
        }
        properties
        categories {
          name
          parent {
            name
          }
        }
      }
    }
  `;
}
