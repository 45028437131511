import { NgIf, NgClass } from "@angular/common";
import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { combineLatest, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { DevicesService } from "src/app/core/services/devices.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { Product } from "src/app/types/product.type";
import { extractImageBasename } from "src/app/tools/cms.tools";

declare var download: any;
declare var $: any;

@Component({
  selector: "fc-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.scss"],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class DownloadComponent implements OnInit {
  @Input({ required: true }) product!: Product;
  @Input() imageId: number = 1;
  @Input() background: boolean = false;

  isMobile: boolean = false;
  canDisplayZipButton: boolean = false;
  zipPath = "";
  zipName = "";
  showSellSheetLink = false;
  sellSheetLink = null;
  imageName = "";

  constructor(
    private devicesService: DevicesService,
    public sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile();

    this.buildImage();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showSellSheetLink = false;
    this.sellSheetLink = null;

    this.buildImage();

    this.buildAndCheckIfZipUrlExists();
  }

  private buildImage(): void {
    this.imageName = this.product.images[this.imageId].smallThumb;
  }

  close() {
    $("#black-overlay").removeClass("visible");
    $("#download-modal").modal("hide");
  }

  download() {
    const imageBasename = extractImageBasename(
      this.product.images[this.imageId].image,
    );
    const imgSrcWithoutExtension = `${this.sharedService.getEnvironmentContainer()}/images-products/originals/${imageBasename}`;
    const imgFullName = this.sharedService.environmentIsPBNA()
      ? `${this.product.eqi}-${this.imageId + 1}_${this.product.name}`
      : `${this.product.colors.length > 0 && this.product.colors[0].fullEqi ? this.product.colors[0].fullEqi : this.product.eqi}}_${this.product.name}`;

    interface IImageVersion {
      response: AjaxResponse<any>;
      type: "png" | "jpg";
      lastModified: Date;
    }
    const pngImage = imgSrcWithoutExtension + ".png";
    const jpgImage = imgSrcWithoutExtension + ".jpg";
    combineLatest([
      ajax(pngImage).pipe(catchError((e) => of(null))),
      ajax(jpgImage).pipe(catchError((e) => of(null))),
    ])
      .pipe(
        tap(([pngImageResult, jpgImageResult]) => {
          const dates: IImageVersion[] = [];
          if (pngImageResult) {
            dates.push({
              response: pngImageResult,
              type: "png",
              lastModified: new Date(
                pngImageResult.xhr.getResponseHeader("Last-Modified")!,
              ),
            });
          }
          if (jpgImageResult) {
            dates.push({
              response: jpgImageResult,
              type: "jpg",
              lastModified: new Date(
                jpgImageResult.xhr.getResponseHeader("Last-Modified")!,
              ),
            });
          }
          if (dates.length > 0) {
            const mostUpToDate = dates.sort(
              (a, b) => b.lastModified.getTime() - a.lastModified.getTime(),
            )[0];
            this.downloadImage(
              mostUpToDate.response.xhr.responseURL,
              imgFullName,
              mostUpToDate.type,
            );
          }
        }),
      )
      .subscribe();
  }

  private urlExists(url: string) {
    var http = new XMLHttpRequest();
    http.open("HEAD", url, false);
    http.send();

    return http.status != 404;
  }

  private downloadImage(url: string, name: string, extension: string) {
    if (this.isMobile) {
      window.open(url, "_blank");
    } else {
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = (e) => {
        download(x.response, name + "." + extension, "image/" + extension);
      };
      x.send();
    }
  }

  private downloadAsZip(url: string, name: string) {
    window.open(url, "_blank");
  }

  private buildAndCheckIfZipUrlExists() {
    if (this.sharedService.environmentIsMCOE()) {
      this.canDisplayZipButton = false;

      return;
    }

    let endPoint =
      this.sharedService.getEnvironmentContainer() + "/images-products/alt/";
    var zipPath = "";
    var zipName = "";

    if (this.product.colors.length && this.product.colors[0].fullEqi) {
      zipPath = endPoint + this.product.colors[0].fullEqi;
      zipName = String(this.product.colors[0].fullEqi);
    } else {
      zipPath = endPoint + this.product.eqi;
      zipName = String(this.product.eqi);
    }

    this.canDisplayZipButton = this.urlExists(zipPath + ".zip");
  }

  downloadZip() {
    let endPoint =
      this.sharedService.getEnvironmentContainer() + "/images-products/alt/";
    var zipPath = "";
    var zipName = "";

    if (this.product.colors.length && this.product.colors[0].fullEqi) {
      zipPath = endPoint + this.product.colors[0].fullEqi;
      zipName = String(this.product.colors[0].fullEqi);
    } else {
      zipPath = endPoint + this.product.eqi;
      zipName = String(this.product.eqi);
    }

    this.downloadAsZip(zipPath + ".zip", zipName);
  }

  openSellSheet() {
    window.open(this.sellSheetLink ?? undefined, "_blank");
  }
}
