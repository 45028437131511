import { Environment } from "../environment.type";

export const environment: Environment = {
  production: true,
  environmentName: 'PBNA - Prod',
  environmentId: 'pbna',
  container: 'https://pbna.blob.core.windows.net',
  endPoint: 'https://pbna-backend.azurewebsites.net',
  graphqlEndPoint: 'https://pbna-backend.azurewebsites.net/graphql',
  frontEnd: 'https://pbna-frontend.azurewebsites.net/',
  analytics: '',
  adminUsername: 'MCOE',
  adminPassword: 'Displays!',
};
