import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Apollo, gql } from "apollo-angular";
import { UploadService } from "./upload.service";
import {
  AddProductInput,
  Product as ProductGQL,
  UpdateProductInput as UpdateProductInputGQL,
  Category as CategoryGQL,
} from "../types/graphql/graphql.type";
import { Product, ProductEditSearch } from "../types/product.type";
import {
  extractImageBasename,
  productGQLtoProduct,
  productGQLtoProductEditSearch,
} from "../tools/cms.tools";

type CreateProductMandatoryProperties =
  | "name"
  | "sortId"
  | "eqi"
  | "hasSellSheet"
  | "colors"
  | "bugs"
  | "categories";

export type CreateProductInput = Pick<
  Product,
  CreateProductMandatoryProperties
> &
  Partial<Omit<Product, CreateProductMandatoryProperties | "images">> & {
    images?: string[];
  };
export type UpdateProductInput = Partial<Omit<Product, "id" | "images">> & {
  id: number;
  images?: string[];
};

type ProductResponse = {
  product: ProductGQL;
};

type ProductVariable = {
  productId: number;
};

type ProductsByBugResponse = {
  productsByBug: ProductGQL[];
};

type ProductsByBugVariables = {
  bugId: number;
};

type ProductsByCategoryResponse = {
  productsByCategory: CategoryGQL;
};

type ProductsByCategoryVariables = {
  categoryId: number;
};

type ProductsByColorResponse = {
  productsByColor: ProductGQL[];
};

type ProductsByColorVariables = {
  colorId: number;
};

type ProductsByKeywordResponse = {
  productsByKeyword: ProductGQL[];
};

type ProductsByKeywordVariables = {
  keyword: string;
};

type CreateProductResponse = {
  createProduct: ProductGQL;
};

type CreateProductVariables = {
  product: AddProductInput;
};

type UpdateProductResponse = {
  updateProduct: ProductGQL;
};

type UpdateProductVariables = {
  product: UpdateProductInputGQL;
};

type DeleteProductResponse = {
  deleteProduct: boolean;
};

type DeleteProductVariables = {
  productId: number;
};

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  private _apollo = inject(Apollo);
  private _uploadService = inject(UploadService);

  getProduct(id: number): Observable<Product> {
    return this._apollo
      .watchQuery<ProductResponse, ProductVariable>({
        query: gql`
          query Product($productId: Int!) {
            product(productId: $productId) {
              id
              name
              sortId
              eqi
              uom
              depth
              width
              height
              size
              metadata
              image
              notes
              properties
              hasSellSheet
              sellSheet {
                displaySpec
                economics
              }
              productColors {
                fullEqi
                color {
                  id
                }
              }
              bugs {
                id
              }
              categories {
                id
                parent {
                  id
                  parent {
                    id
                  }
                }
              }
            }
          }
        `,
        variables: { productId: id },
      })
      .valueChanges.pipe(
        map(({ data: { product } }) => productGQLtoProduct(product)),
      );
  }

  getProductsByBug(id: number): Observable<Product[]> {
    return this._apollo
      .watchQuery<ProductsByBugResponse, ProductsByBugVariables>({
        query: gql`
          query ProductsByBug($bugId: Int!) {
            productsByBug(bugId: $bugId) {
              id
              name
              sortId
              eqi
              uom
              depth
              width
              height
              size
              metadata
              image
              notes
              properties
              hasSellSheet
              sellSheet {
                displaySpec
                economics
              }
              productColors {
                fullEqi
                color {
                  id
                }
              }
              bugs {
                id
              }
              categories {
                id
                parent {
                  id
                  parent {
                    id
                  }
                }
              }
            }
          }
        `,
        variables: { bugId: id },
      })
      .valueChanges.pipe(
        map(({ data: { productsByBug } }) =>
          productsByBug.map((p) => productGQLtoProduct(p)),
        ),
      );
  }

  getProductsByColor(id: number): Observable<Product[]> {
    return this._apollo
      .watchQuery<ProductsByColorResponse, ProductsByColorVariables>({
        query: gql`
          query ProductsByColor($colorId: Int!) {
            productsByColor(colorId: $colorId) {
              id
              name
              sortId
              eqi
              uom
              depth
              width
              height
              size
              metadata
              image
              notes
              properties
              hasSellSheet
              sellSheet {
                displaySpec
                economics
              }
              productColors {
                fullEqi
                color {
                  id
                }
              }
              bugs {
                id
              }
              categories {
                id
                parent {
                  id
                  parent {
                    id
                  }
                }
              }
            }
          }
        `,
        variables: { colorId: id },
      })
      .valueChanges.pipe(
        map(({ data: { productsByColor } }) =>
          productsByColor.map((p) => productGQLtoProduct(p)),
        ),
      );
  }

  getProductsByCategory(id: number): Observable<Product[]> {
    return this._apollo
      .watchQuery<ProductsByCategoryResponse, ProductsByCategoryVariables>({
        query: gql`
          query ProductsEditSearchByCategory($categoryId: Int!) {
            productsByCategory(categoryId: $categoryId) {
              products {
                id
                name
                sortId
                eqi
                uom
                depth
                width
                height
                size
                metadata
                image
                notes
                properties
                hasSellSheet
                sellSheet {
                  displaySpec
                  economics
                }
                productColors {
                  fullEqi
                  color {
                    id
                  }
                }
                bugs {
                  id
                }
                categories {
                  id
                  parent {
                    id
                    parent {
                      id
                    }
                  }
                }
              }
              children {
                products {
                  id
                  name
                  sortId
                  eqi
                  uom
                  depth
                  width
                  height
                  size
                  metadata
                  image
                  notes
                  properties
                  hasSellSheet
                  sellSheet {
                    displaySpec
                    economics
                  }
                  productColors {
                    fullEqi
                    color {
                      id
                    }
                  }
                  bugs {
                    id
                  }
                  categories {
                    id
                    parent {
                      id
                      parent {
                        id
                      }
                    }
                  }
                }
                children {
                  products {
                    id
                    name
                    sortId
                    eqi
                    uom
                    depth
                    width
                    height
                    size
                    metadata
                    image
                    notes
                    properties
                    hasSellSheet
                    sellSheet {
                      displaySpec
                      economics
                    }
                    productColors {
                      fullEqi
                      color {
                        id
                      }
                    }
                    bugs {
                      id
                    }
                    categories {
                      id
                      parent {
                        id
                        parent {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { categoryId: id },
      })
      .valueChanges.pipe(
        map(
          ({ data: { productsByCategory } }) =>
            productsByCategory.products
              ?.map((p) => productGQLtoProduct(p))
              .sort((a, b) => a.sortId - b.sortId) ?? [],
        ),
      );
  }

  getProductsByKeyword(text: string): Observable<Product[]> {
    return this._apollo
      .watchQuery<ProductsByKeywordResponse, ProductsByKeywordVariables>({
        query: gql`
          query ProductsByKeyword($keyword: String!) {
            productsByKeyword(keyword: $keyword) {
              id
              name
              sortId
              eqi
              uom
              depth
              width
              height
              size
              metadata
              image
              notes
              properties
              hasSellSheet
              sellSheet {
                displaySpec
                economics
              }
              productColors {
                fullEqi
                color {
                  id
                }
              }
              bugs {
                id
              }
              categories {
                id
                parent {
                  id
                  parent {
                    id
                  }
                }
              }
            }
          }
        `,
        variables: { keyword: text },
      })
      .valueChanges.pipe(
        map(({ data: { productsByKeyword } }) =>
          productsByKeyword.map((p) => productGQLtoProduct(p)),
        ),
      );
  }

  getProductsEditSearchByBug(id: number): Observable<ProductEditSearch[]> {
    return this._apollo
      .watchQuery<ProductsByBugResponse, ProductsByBugVariables>({
        query: gql`
          query ProductsEditSearchByBug($bugId: Int!) {
            productsByBug(bugId: $bugId) {
              id
              name
              eqi
              image
              categories {
                id
                name
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
              productColors {
                color {
                  id
                }
              }
              bugs {
                id
              }
            }
          }
        `,
        variables: { bugId: id },
      })
      .valueChanges.pipe(
        map(({ data: { productsByBug } }) =>
          productsByBug.map((p) => productGQLtoProductEditSearch(p)),
        ),
      );
  }

  getProductsEditSearchByCategory(id: number): Observable<ProductEditSearch[]> {
    return this._apollo
      .watchQuery<ProductsByCategoryResponse, ProductsByCategoryVariables>({
        query: gql`
          query ProductsEditSearchByCategory($categoryId: Int!) {
            productsByCategory(categoryId: $categoryId) {
              products {
                id
                name
                eqi
                image
                categories {
                  id
                  name
                  parent {
                    id
                    name
                    parent {
                      id
                      name
                    }
                  }
                }
                productColors {
                  color {
                    id
                  }
                }
                bugs {
                  id
                }
              }
              children {
                products {
                  id
                  name
                  eqi
                  image
                  categories {
                    id
                    name
                    parent {
                      id
                      name
                      parent {
                        id
                        name
                      }
                    }
                  }
                  productColors {
                    color {
                      id
                    }
                  }
                  bugs {
                    id
                  }
                }
                children {
                  products {
                    id
                    name
                    eqi
                    image
                    categories {
                      id
                      name
                      parent {
                        id
                        name
                        parent {
                          id
                          name
                        }
                      }
                    }
                    productColors {
                      color {
                        id
                      }
                    }
                    bugs {
                      id
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { categoryId: id },
      })
      .valueChanges.pipe(
        map(({ data: { productsByCategory } }) => {
          const products: ProductEditSearch[] = [];

          if (productsByCategory.products) {
            products.push(
              ...productsByCategory.products.map((p) =>
                productGQLtoProductEditSearch(p),
              ),
            );
          }

          const l2Products = productsByCategory.children.reduce<
            ProductEditSearch[]
          >((products, l2Category) => {
            if (l2Category.products) {
              products.push(
                ...l2Category.products.map((p) =>
                  productGQLtoProductEditSearch(p),
                ),
              );
            }

            const l3Products = l2Category.children.reduce<ProductEditSearch[]>(
              (products, l3Category) => {
                if (l3Category.products) {
                  products.push(
                    ...l3Category.products.map((p) =>
                      productGQLtoProductEditSearch(p),
                    ),
                  );
                }
                return products;
              },
              [],
            );
            products.push(...l3Products);

            return products;
          }, []);

          products.push(...l2Products);

          return products;
        }),
      );
  }

  getProductEditSearch(id: number): Observable<ProductEditSearch> {
    return this._apollo
      .watchQuery<ProductResponse, ProductVariable>({
        query: gql`
          query Product($productId: Int!) {
            product(productId: $productId) {
              id
              name
              eqi
              image
              categories {
                id
                name
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
              productColors {
                color {
                  id
                }
              }
              bugs {
                id
              }
            }
          }
        `,
        variables: { productId: id },
      })
      .valueChanges.pipe(
        map(({ data: { product } }) => productGQLtoProductEditSearch(product)),
      );
  }

  getProductsEditSearchByColor(id: number): Observable<ProductEditSearch[]> {
    return this._apollo
      .watchQuery<ProductsByColorResponse, ProductsByColorVariables>({
        query: gql`
          query ProductsEditSearchByColor($colorId: Int!) {
            productsByColor(colorId: $colorId) {
              id
              name
              eqi
              image
              categories {
                id
                name
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
              productColors {
                color {
                  id
                }
              }
              bugs {
                id
              }
            }
          }
        `,
        variables: { colorId: id },
      })
      .valueChanges.pipe(
        map(({ data: { productsByColor } }) =>
          productsByColor.map((p) => productGQLtoProductEditSearch(p)),
        ),
      );
  }

  getProductsEditSearchByKeyword(
    text: string,
  ): Observable<ProductEditSearch[]> {
    return this._apollo
      .watchQuery<ProductsByKeywordResponse, ProductsByKeywordVariables>({
        query: gql`
          query ProductsEditSearchByKeyword($keyword: String!) {
            productsByKeyword(keyword: $keyword) {
              id
              name
              eqi
              image
              categories {
                id
                name
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
              productColors {
                color {
                  id
                }
              }
              bugs {
                id
              }
            }
          }
        `,
        variables: { keyword: text },
      })
      .valueChanges.pipe(
        map(({ data: { productsByKeyword } }) =>
          productsByKeyword.map((p) => productGQLtoProductEditSearch(p)),
        ),
      );
  }

  uploadProductImage(file: File): Observable<string> {
    return this._uploadService.uploadProductImage(file).upload$;
  }

  createProduct(productData: CreateProductInput): Observable<void> {
    return this._apollo
      .mutate<CreateProductResponse, CreateProductVariables>({
        mutation: gql`
          mutation CreateProduct($product: AddProductInput!) {
            createProduct(product: $product) {
              id
            }
          }
        `,
        variables: {
          product: this.createProductInputToAddProductInput(productData),
        },
        refetchQueries: "active",
      })
      .pipe(map(({ data }) => {}));
  }

  updateProduct(productData: UpdateProductInput): Observable<void> {
    return this._apollo
      .mutate<UpdateProductResponse, UpdateProductVariables>({
        mutation: gql`
          mutation UpdateProduct($product: UpdateProductInput!) {
            updateProduct(product: $product) {
              id
            }
          }
        `,
        variables: {
          product: this.updateProductInputToUpdateProductInputGQL(productData),
        },
        refetchQueries: "active",
      })
      .pipe(map(({ data }) => {}));
  }

  deleteProduct(id: number): Observable<void> {
    return this._apollo
      .mutate<DeleteProductResponse, DeleteProductVariables>({
        mutation: gql`
          mutation DeleteProduct($productId: Int!) {
            deleteProduct(productId: $productId)
          }
        `,
        variables: { productId: id },
        update: (cache, { data }) => {
          if (data?.deleteProduct) {
            const identity = cache.identify({ id, __typename: "Product" });
            cache.evict({ id: identity, broadcast: false });
            cache.evict({ fieldName: "productsByKeyword", broadcast: true });
            cache.evict({ fieldName: "productsByBug", broadcast: true });
            cache.evict({ fieldName: "productsByCategory", broadcast: true });
            cache.evict({ fieldName: "productsByColor", broadcast: true });
            cache.gc();
          }
        },
      })
      .pipe(
        map(({ data }) => {
          if (!data?.deleteProduct) {
            throw "Error deleting Product";
          }
        }),
      );
  }

  private createProductInputToAddProductInput(
    data: CreateProductInput,
  ): AddProductInput {
    const { image, images } = this.prepareProductImages(data);
    const properties = this.prepareProductProperties(data, images);

    return {
      name: data.name,
      sortId: data.sortId,
      eqi: data.eqi,
      hasSellSheet: data.hasSellSheet,
      colorsId: data.colors.map((c) => `${c.id}`),
      bugsId: [...data.bugs.map((b) => `${b}`)],
      categoriesId: data.categories.map((c) => c.l3 ?? c.l2 ?? c.l1),
      image: image,
      ...(data.uom !== undefined && { uom: data.uom }),
      ...(data.depth !== undefined && { depth: data.depth }),
      ...(data.width !== undefined && { width: data.width }),
      ...(data.height !== undefined && { height: data.height }),
      ...(data.size !== undefined && { size: data.size }),
      ...(data.metadata !== undefined && { metadata: data.metadata.join(",") }),
      ...(data.notes !== undefined && { notes: data.notes }),
      ...(data.properties !== undefined && {
        properties: JSON.stringify(properties),
      }),
      ...(data.sellsheet !== undefined && {
        sellSheetData: {
          displaySpec: data.sellsheet?.displaySpec
            ? JSON.stringify(data.sellsheet?.displaySpec)
            : "",
          economics: data.sellsheet?.economics
            ? JSON.stringify(data.sellsheet?.economics)
            : "",
        },
      }),
    };
  }

  private updateProductInputToUpdateProductInputGQL(
    data: UpdateProductInput,
  ): UpdateProductInputGQL {
    const { image, images } = this.prepareProductImages(data);
    const properties = this.prepareProductProperties(data, images);

    return {
      id: data.id,
      ...(data.name !== undefined && { name: data.name }),
      ...(data.sortId !== undefined && { sortId: data.sortId }),
      ...(data.eqi !== undefined && { eqi: data.eqi }),
      ...(data.hasSellSheet !== undefined && {
        hasSellSheet: data.hasSellSheet,
      }),
      ...(data.colors !== undefined && {
        colorsId: data.colors.map((c) => Number(c.id)), // Temporary until ID type is fixed in backend
      }),
      ...(data.bugs !== undefined && {
        bugsId: [...data.bugs.map((b) => Number(b))], // Temporary until ID type is fixed in backend
      }),
      ...(data.categories !== undefined && {
        categoriesId: data.categories.map((c) => Number(c.l3 ?? c.l2 ?? c.l1)), // Temporary until ID type is fixed in backend
      }),
      // If image is defined, it needs to be updated
      // If images is defined, it needs to be updated even if image is undefined in which case it indicates the display has no images
      // If none are defined, images don't need to be updated
      ...((image || images) && { image: image ?? null }), // Passing undefined will get stripped by Apollo, so we pass null instead (in case image needs to be removed)
      ...(data.uom !== undefined && { uom: data.uom }),
      ...(data.depth !== undefined && { depth: data.depth }),
      ...(data.width !== undefined && { width: data.width }),
      ...(data.height !== undefined && { height: data.height }),
      ...(data.size !== undefined && { size: data.size }),
      ...(data.metadata !== undefined && { metadata: data.metadata.join(",") }),
      ...(data.notes !== undefined && { notes: data.notes }),
      ...(data.properties !== undefined && {
        properties: JSON.stringify(properties),
      }),
      ...(data.sellsheet !== undefined && {
        sellSheetData: {
          displaySpec: data.sellsheet?.displaySpec
            ? JSON.stringify(data.sellsheet?.displaySpec)
            : "",
          economics: data.sellsheet?.economics
            ? JSON.stringify(data.sellsheet?.economics)
            : "",
        },
      }),
    };
  }

  private prepareProductImages(
    product: CreateProductInput | UpdateProductInput,
  ): {
    image: string | undefined;
    images: string[] | undefined;
  } {
    let image: string | undefined;
    let images: string[] | undefined;
    if (product.images) {
      images = [];
      if (product.images.length > 0) {
        image = `${extractImageBasename(product.images[0])}.jpg`;
        for (let i = 1; i < product.images.length; i++) {
          images.push(`${extractImageBasename(product.images[i])}.jpg`);
        }
      }
    }

    return { image, images };
  }

  private prepareProductProperties(
    product: CreateProductInput | UpdateProductInput,
    images?: string[],
  ): any {
    const properties: any = product.properties ? { ...product.properties } : {};
    delete properties.imagesCount;
    if (images) {
      properties["images"] = images;
    }

    return properties;
  }
}
