import { Injectable } from '@angular/core';

import { Query, gql } from 'apollo-angular';

export interface MenuCategory {
  id?: number;
  name?: string;
  parent?: number;
  children?: MenuCategory[];
}

export interface MenuCategories {
  categories: MenuCategory[];
}

@Injectable({
  providedIn: 'root'
})
export class MenuCategoriesService extends Query<MenuCategories> {
  override document = gql`
    query {
      categories { 
        id, name,  parent { id }, children { 
          id, name, parent { id }, children { 
            id, name, parent { id }
          }
        }
      }
    }`;
}
