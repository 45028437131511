import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router'
import { NgClass, NgIf } from '@angular/common';

import { environment } from 'src/environments/environment'

import { DevicesService } from 'src/app/core/services/devices.service';
import { Store } from '@ngrx/store';
import { AppState } from './store/state/app.state';
import { ISiteSettings, DisplayStyle } from './store/models/ISiteSettings';
import { SharedService } from './shared/services/shared.service';

import { setSiteSettingsDisplayStyleAction } from './store/actions/SiteSettings.actions';
import { CustomPopupComponent } from './shared/components/custom-popup/custom-popup.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { ScrollSpyDirective } from './shared/scroll-spy.directive';
import { BreadcrumbComponent } from './public/product/breadcrumb/breadcrumb.component';
import { MenuTreeComponent } from './shared/components/menu/menu-tree/menu-tree.component';
import { MenuDesktopComponent } from './core/components/menu/desktop/menu.desktop.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, NavbarComponent, MenuDesktopComponent, MenuTreeComponent, BreadcrumbComponent, ScrollSpyDirective, RouterOutlet, FooterComponent, CustomPopupComponent]
})
export class AppComponent implements OnInit, AfterViewChecked {
  isMenuLoaded: boolean = false;
  showProductsLeftNavigation: boolean = false
  isMobile: boolean = false
  isPrint: boolean = false
  isBugs = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private devicesService: DevicesService,
    private cdRef: ChangeDetectorRef,
    public sharedService: SharedService,
  ) {
    this
      .store
      .select(state => state.siteSettings)
      .subscribe((s: ISiteSettings) => this.isBugs = s ? s.isBugs : false)
      ;

    if (sharedService.environmentIsMCOE()) {
      document.title = "Fritolay Catalog"
    } else if (sharedService.environmentIsFSV()) {
      document.title = "PepsiCo - Foodservice"
    } else if(sharedService.environmentIsPBNA()) {
      document.title = "PBNA"
    }
  }

  ngOnInit(): void {
    this.isMobile = this.devicesService.isMobile()

    this.store.dispatch(setSiteSettingsDisplayStyleAction({displayStyle: DisplayStyle.Cards}));

    console.log('Current environment is: ' + environment.environmentName + '. Current endpoint: ' + environment.endPoint + '. GA/GTM: ' + environment.analytics)
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onMenuLoaded(event: any) {
    this.isMenuLoaded = event
  }

  public onRouterOutletActivate(event: any) {
    if (this.router.url.indexOf('/print/') > -1 || this.router.url.indexOf('/sellsheet/') > -1) {
      this.isPrint = true
    } else {
      this.isPrint = false

      if (this.router.url.indexOf('products/') > -1) {
        this.showProductsLeftNavigation = true
      } else {
        this.showProductsLeftNavigation = false
      }
    }
  }

  epopInventoryReportClicked() {
    if (this.sharedService.environmentIsMCOE()) {
      window.open("https://pepsico.sharepoint.com/sites/Flna_Sales/mcoe/SitePages/Inventory%20Reports.aspx", "_blank");
    } else {
      window.open("https://pepsico.sharepoint.com/sites/Flna_Sales/mcoe/away_from_home/SitePages/Reports.aspx", "_blank");
    }
  }

  dcInventoryReportClicked() {
    if (this.sharedService.environmentIsMCOE()) {
      window.open("https://pepsico.sharepoint.com/sites/Flna_Sales/mcoe/SitePages/Facility%20Inventory.aspx", "_blank");
    } else {
    }
  }

  sellSheetsClicked() {
    if (this.sharedService.environmentIsMCOE()) {
      window.open("https://pepsico.sharepoint.com/sites/Flna_Sales/mcoe/SitePages/Sell-Sheets.aspx", "_blank");
    } else {
      window.open("https://pepsico.sharepoint.com/sites/Flna_Sales/mcoe/away_from_home/SitePages/Sell-Sheets.aspx", "_blank");
    }
  }

  closeInventoryModal() {
    $("#black-overlay").removeClass('visible')
    $('#inventory-modal').modal('hide');
  }
}
