<div class="container landscape">
  <div class="header">
    <div class="line line-1"></div>
    <div class="line line-2"></div>
    <div class="name">
      {{ product.name }}
    </div>
  </div>
  <div class="content">
    <div class="column">
      <div class="section rationale">
        <div class="title">Rationale</div>
        <div class="section-content">
          <ul>
            @for (r of rationale; track $index) {
              <li>{{ r }}</li>
            }
          </ul>
        </div>
      </div>
      @if (
        product.sellsheet?.economics?.turnsPerWeek ||
        product.sellsheet?.economics?.estimatedTurnForBreakeven ||
        product.sellsheet?.economics?.unitsToBreakeven ||
        product.sellsheet?.economics?.estimatedAnnualProfitability
      ) {
        <div class="section roi">
          <div class="roi-title">PME Breakeven/ROI</div>
          <div class="roi-table">
            <div class="roi-cell roi-header">Turns per Week</div>
            <div class="roi-cell roi-header">Estimated turn for Breakeven</div>
            <div class="roi-cell roi-header">Units to Breakeven</div>
            <div class="roi-cell roi-header">
              Estimated Annual Profitability
            </div>

            <div class="roi-cell roi-item">
              {{ product.sellsheet?.economics?.turnsPerWeek }}
            </div>
            <div class="roi-cell roi-item">
              {{ product.sellsheet?.economics?.estimatedTurnForBreakeven }}
            </div>
            <div class="roi-cell roi-item">
              {{ product.sellsheet?.economics?.unitsToBreakeven }}
            </div>
            <div class="roi-cell roi-item">
              {{ product.sellsheet?.economics?.estimatedAnnualProfitability }}
            </div>
          </div>
        </div>
      }
    </div>
    <div class="column">
      <div class="section locations">
        <div class="title">Suggested Locations</div>
        <div class="section-content">
          <ul>
            @for (location of suggestedLocations; track $index) {
              <li>{{ location }}</li>
            }
          </ul>
        </div>
      </div>
      <div class="section keys-attr">
        <div class="title">Key Attributes</div>
        <div class="section-content">
          <div class="title-attr">Specifications:</div>
          <ul class="bullet">
            @for (specification of specifications; track $index) {
              <li>
                {{ specification }}
              </li>
            }
          </ul>
          <div class="title-attr">Packout:</div>
          <ul>
            @for (p of packout; track $index) {
              <li>{{ p }}</li>
            }
          </ul>
        </div>
      </div>
    </div>
    <div class="column round">
      <div class="section product">
        <div class="details">
          <div class="product-image">
            <img [src]="image" />
          </div>
          <span class="order">Order Details</span>
          <span
            >{{ product.properties?.unitsPerCarton ?? "" }} display /
            carton</span
          >
          <span class="space">{{ product.eqi }}</span>
          <span class="space">{{
            product.properties?.price ?? product.sellsheet?.economics?.price
          }}</span>
          <span>{{ product.sellsheet?.economics?.fundingLevel }}</span>
        </div>
        <div class="dimensions">
          <span>Dimensions</span>
          15'W x 20"D x 70"H
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="line"></div>
    <div class="page">{{ page }}</div>
  </div>
</div>
